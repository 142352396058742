import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  urls: [],
};

const inFlightReducer = createReducer(initialState, {
  ADD_URL: (state, action) => {
    if(state.urls.find((url) => url === action.url)) { return; }
    state.urls.push(action.url);
  },
  REMOVE_URL: (state, action) => {
    state.urls = state.urls.filter(value => value !== action.url);
  },
  REMOVE_ALL_URLS: (state) => {
    state.urls = [];
  },
});

export default inFlightReducer;
