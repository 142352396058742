import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux';
import FileUpload from '../shared/FileUpload';
import Button from '../shared/Button';
import Modal from '../high-order/Modal';
import ProgressBar from '../shared/ProgressBar';
import { getModel } from 'qcp-js-ui-core/models';
import { handleActions } from 'qcp-js-ui-core/actions';
import styled, { ThemeContext } from 'styled-components';
import { failureNotificationMessage } from '../shared/Spinner';
import { addFilterOut, removeFilterOut } from '../../store/actions/notification';
const AWS = require('aws-sdk');

import { LOCAL_URI } from 'qcp-js-ui-core/constants';
import { generateTempQuin } from 'qcp-js-ui-core/utils/uuid';
import {
  addDefaultModel,
  updateProperty,
  insertItem,
} from 'qcp-js-ui-core/store/actions/rms';
import { setNotification } from '../../store/actions/notification';

var progressLoaded = 0;
const formatFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

async function addAttachment({ quin, dispatch, getState }, file) {
  const { type, name } = file;
  const fileType = type ? type.split('/')[0]: 'other';
  const tempQuin = generateTempQuin();
  dispatch(addDefaultModel(tempQuin, 'attachment'));
  dispatch(updateProperty(tempQuin, 'fileType', fileType));
  dispatch(updateProperty(tempQuin, 'fileName', name));
  await dispatch(
    handleActions({
      json: 'create',
      quin: tempQuin,
    }),
  );

  const awsAttachmentParameter = getModel(tempQuin, getState().rms)
    ?.awsAttachmentParameters?.[0];
  if (awsAttachmentParameter) {
    const credential = awsAttachmentParameter.Credentials[0];
    const s3Object = awsAttachmentParameter.S3Object[0];
    await uploadFile({
      ...credential,
      ...s3Object,
      file,
      dispatch: dispatch,
      quin,
      tempQuin,
    });
  }
}

const uploadFile = async ({
  SecretAccessKey,
  SessionToken,
  AccessKeyId,
  SSEKMSKeyId,
  Bucket,
  Key,
  Region,
  file,
  dispatch,
  quin,
  tempQuin,
}) => {
  let s3 = new AWS.S3({
    credentials: new AWS.Credentials(
      AccessKeyId,
      SecretAccessKey,
      SessionToken,
    ),
    region: Region,
  });
  const reader = new FileReader();

  reader.onabort = () => console.error('file reading was aborted');
  reader.onerror = () => console.error('file reading has failed');
  reader.onload = () => {
    const arrayBuffer = reader.result;

    const uploadParams = {
      Bucket: Bucket,
      Key: Key,
      Body: arrayBuffer,
      // ContentEncoding: "base64",
      ContentType: file.type,
      ...(typeof SSEKMSKeyId !== 'undefined' && { ServerSideEncryption: 'aws:kms' }),
      ...(typeof SSEKMSKeyId !== 'undefined' && { SSEKMSKeyId: SSEKMSKeyId })
    };

    s3.upload(uploadParams).on('httpUploadProgress', function (progress) {
      progressLoaded += progress.loaded;
      let ele = document.getElementById('uploadProgress');
      ele.innerHTML = formatFileSize(progressLoaded);
      ele.setAttribute('data-progress', Number(progressLoaded));
    }).send(function (error, data) {
      if (error) {
        dispatch(setNotification('File upload failed. Please try again.'));
        console.log(error);
      } else {
        // Only successful uploads should run the inserts and updates
        dispatch(insertItem(quin, 'attachment', tempQuin));
        (async() => await dispatch(
          handleActions({
            json: 'update',
            quin: quin,
          }),
        ))();
        dispatch(updateProperty(tempQuin, LOCAL_URI, name));
        console.log('S3 Upload Success', data.Location);
        progressLoaded = 0;
      }
    });
  };
  reader.readAsArrayBuffer(file);
};

const FileUploadModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const modalContents = useSelector(state => state.modal?.contents);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(0);
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    let totalFileSize = 0;
    if(files.length) {
      files.map((file) => {
        totalFileSize = totalFileSize + file.size;
      });
      setTotalSize(totalFileSize);
    }
  }, [files]);

  const progressTimer = () => {
    setInterval(() => {
      if(document.getElementById('uploadProgress')) {
        let ele = document.getElementById('uploadProgress');
        let dataProgress = ele.getAttribute('data-progress');
        if(Number(dataProgress) >= totalSize) {
          setUploadCompleted(1);
          progressLoaded = 0;
          setTotalSize(0);
          setProgress(0);
          clearInterval(progressTimer);
        } else {
          setProgress(Number(progressLoaded));
        }
      }
    },500);
  };

  useEffect(() => {
    if(uploadCompleted) {
      let ele = document.getElementById('uploadProgress');
      ele.setAttribute('data-progress', 0);

      closeModal();
    }
  }, [uploadCompleted]);

  const bringBack = () => {
    dispatch(removeFilterOut(failureNotificationMessage));
  }

  const handleSubmit = () => {
    dispatch(addFilterOut(failureNotificationMessage));
    setTimeout(bringBack, 40000);
    progressTimer();
    if(!files.length) {
      return;
    }
    Promise.all(files.map(async (file) => {
      await addAttachment(modalContents, file);
    }));

    setFiles([]);
  };

  return (
    <>
      <Modal onClose={closeModal} visible={true} title="File Upload">
        <FileUpload
          files={files}
          setFiles={setFiles}
        />
        <Wrapper>
          <Container activeTheme={themeContext}>
            <span data-progress="0" id="uploadProgress">0</span> /
            <span data-total-size={totalSize} id="totalFileSize">{formatFileSize(totalSize)}</span>
          </Container>
          <ProgressBar value={progress} max={totalSize} />
          <Button enabled={progressLoaded === 0 && files.length > 0 ? true : false} onPress={handleSubmit} visible={true} label="Submit" />
        </Wrapper>
      </Modal>
    </>
  );
};

const Container = styled.div`
color: ${({ activeTheme }) => activeTheme.modalTitle};

span {
color: ${({ activeTheme }) => activeTheme.modalTitle};
}
align-items: center;
`

const Wrapper = styled.div`
flex: 1;
flex-direction: row;
`

export default FileUploadModal;