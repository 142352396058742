import React from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Modal from '../high-order/Modal';
import Button from '../shared/Button';
import Title from '../shared/Title';
import styled from 'styled-components';
import { hideModal } from '../../store/actions/modal';
import { changeModalConfirmationResult } from 'qcp-js-ui-core/store/actions/rms';

const Confirm = () => {
    const dispatch = useDispatch();
    const {message,continueText,cancelText} = useSelector(state => state.modal?.contents);

    const confirmYes = () => {
        dispatch(changeModalConfirmationResult("yes"));
        dispatch(hideModal());
    };

    const confirmNo = () => {
        dispatch(changeModalConfirmationResult("no"));
        dispatch(hideModal());
    };

    return (
    <StyledModal visible={true} onClose={confirmNo} >
            <Content>
                <p>{message}</p>
            </Content>
            <Footer>
                <StyledButton type="primary" className={'cancel'} label={cancelText} onPress={confirmNo} />
                <StyledButton type="primary" label={continueText} onPress={confirmYes} />
            </Footer>
    </StyledModal>);
}
const StyledButton = styled(Button)`
margin: 10px 30px;
&.cancel {
    background: #4d8cc0;

    &:hover {
        opacity: .75;
    }
}
`

const Content = styled.div`
display: flex;
margin: 5px 25px;
max-width: 300px;
margin: 0 auto;

p {
    font-size: 1rem;
}
`;
const StyledModal = styled(Modal)`
position: relative;
margin: auto;
top: 0px;
bottom: 0px;
min-height: 200px;
min-width: 400px;
width: fit-content;
height: fit-content;
`;
const Footer = styled.div`
justify-content: space-between;
background: #f5f9fa;
margin: auto -5px 0;
border-top: 1px solid #c7c7c7;
`;
export default Confirm;