import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import Field from '../high-order/Field';
import { makeConversion } from 'qcp-js-ui-core/utils/unit-conversion';
import { Label, Col } from '../common';
import theme from '../theme';

const Height = ({generateEngine}) => {
  const [height, setHeight] = useState({
    onChange: () => null,
    displayValue: '',
    rowCount: 1,
    isMultiLine: false,
    enabled: false,
    label: '',
    isNIBRS: false,
  });
  const [feet, setFeet] = useState(0);
  const [inches, setInches] = useState(0);
  const themeContext = useContext(ThemeContext);

  useEffect(() => generateEngine(setHeight), []);
  useEffect(() => {
    if(height.value) {
      const conversion = makeConversion(height.units ? height.units : 'feetAndInches', height.value);
      setFeet(conversion.feet.toFixed(0));
      setInches(conversion.inches.toFixed(0));
    }
  }, [height]);
  const {
    onChange,
    enabled,
    label,
    isNIBRS,
  } = height;

  const showLabel = true;
  const required = false;

  const showError = false;
  const error = 'this should never happen';

  const handleHeightFtChange = (event) => {
    setFeet(event.target.value);
    onChange({feet: event.target.value, inches: inches});
  };
  const handleHeightInchesChange = (event) => {
    setInches(event.target.value);
    onChange({feet: feet, inches: event.target.value});
  };

  return (
    <Col>
      <Wrapper>
        <StyledLabel disabled={!enabled} isNIBRS={isNIBRS} activeTheme={themeContext}>
          {showLabel && label}
        </StyledLabel>
        <InputWrapper activeTheme={themeContext}>
          <input
            disabled={!enabled}
            value={feet}
            required={required}
            type="text"
            placeholder={'ft'}
            onChange={handleHeightFtChange}
          />
          <input
            disabled={!enabled}
            value={inches}
            required={required}
            type="text"
            placeholder={'inches'}
            onChange={handleHeightInchesChange}
          />
        </InputWrapper>
        {showError && (
          <Label error style={{ textAlign: 'right' }}>
            {error}
          </Label>
        )}
      </Wrapper>
    </Col>
  );
};

Height.displayName = 'Height';

Height.propTypes = { generateEngine: PropTypes.func };

export default Height;
export const FieldInput = Field(Height);

const Wrapper = styled.div`
flex: ${({ flex }) => flex};
flex-direction: column;
min-height: ${({ showLabel }) =>
    theme.wrapperHeight - Number(!showLabel) * theme.labelFullHeight}px;
padding: ${theme.componentPadding};
width: 100%;


`;

const InputWrapper = styled.div`
flex-direction: row;
> input {
height: ${theme.inputHeight}px;
min-height: ${theme.inputMaxHeight}px;
}
> input:nth-of-type(1) {
margin-right: 10px;
}
> input,
> textarea {
width: 50%;
border: ${theme.inputBorder};
border-radius: ${theme.borderRadius}px;
display: flex;
font: inherit;
font-size: .75rem;
outline: 0;
padding: ${theme.inputPadding}px ${theme.inputPadding * 2}px;
transition: ${theme.inputTransition};
background: ${({ activeTheme }) => activeTheme.inputBackground};
color: ${({ activeTheme }) => activeTheme.inputText};
filter: ${({ activeTheme }) => activeTheme?.filter};
&:disabled {
background: ${({ activeTheme }) => activeTheme.inputBackground};
color: ${theme.disabledColor};
cursor: not-allowed;
}
&:focus {
border-color: ${theme.primary};
}
&::placeholder {
color: ${theme.grey};
}
${({ showError }) =>
    showError &&
css`
border: 1px solid ${theme.error};
`}
`;

const StyledLabel = styled(Label)`
color: ${({ isNIBRS, activeTheme }) => isNIBRS ? activeTheme?.labelNIBRS : activeTheme?.label};
margin-left: 10px;
`;
