import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { shallowEqual, useSelector } from 'react-redux';
import { Col, H4 } from '../common';

const Title = ({
  value,
}) => {
    const themeContext = useContext(ThemeContext);
  const displayed = (typeof value === 'string' ? value : useSelector(state => getValueFromBindingObject(value, state),
    shallowEqual,
  ));

  return (
    <Wrapper>
      <Col>
        <Text activeTheme={themeContext}>{displayed}</Text>
      </Col>
    </Wrapper>
  );
};

Title.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  horizontalAlign: PropTypes.string,
};

export default Title;

const Wrapper = styled.div`
padding: 0 5px;
`;

const Text = styled(H4)`
    color: ${({ activeTheme }) => activeTheme.text};
    filter: ${({ activeTheme }) => activeTheme.filter};
`
