import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  message: null,
  filteredOutMessages: [],
};

const notificationReducer = createReducer(initialState, {
  ADD_FILTER_OUT: (state, {message}) => {
    const filters = [...state.filteredOutMessages]
    filters.push(message);
    state.filteredOutMessages = filters;
  },
  REMOVE_FILTER_OUT: (state, {message}) => {
    const messages = state.filteredOutMessages;
    state.filteredOutMessages = messages.filter(v => message !== v);
  },
  SET_NOTIFICATION: (state, action) => {
    if(state.filteredOutMessages.includes(action.message)) { return }
    state.message = action.message;
  },
  CLEAR_NOTIFICATION: (state) => {
    state.message = null;
  },
});

export default notificationReducer;
