import React, { Fragment } from 'react';

import { Col, Row } from '../common';
import { useSelector } from 'react-redux';
import { getModel } from 'qcp-js-ui-core/models';
import { getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import ViewComponent from '../ViewComponent';

const ListRow = ({ id, template }) => {

  const route = {
    link: `/${template}/$`,
    quin: id,
    key: template,
  };

  return <ViewComponent route={route} />;
};

const List = ({ binding, template, setShowLoading }) => {

  const reducer = (result, current) => {
    if(!Array.isArray(current)) {
      result.push(current.destQuin || current);
      return result;
    }
    current.forEach((value => {
      result.push(value.destQuin || value);
    }));
    return result;
  };

  const values = useSelector(
    state => {
        if(binding.id) {
          const model = getModel(binding.id, state.rms);
          if(Array.isArray(model?._array)) {
            return model._array;
          }
        }
        const works = getValueFromBindingObject(binding, state);
        return Array.isArray(works) ? works : [];
    }
  ).reduce(reducer, []);
  return (
    values.map((valueId, index) => (
      <Fragment key={`${valueId}-${index}`}>
        <Row>
          <Col>
            <ListRow
              id={valueId}
              template={template}
              setShowLoading={setShowLoading}
            />
          </Col>
        </Row>    
      </Fragment>
    ))
  );
};

export default List;