import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from '../shared/Icon';
import { animated, useSpring } from 'react-spring';
import styled, { css, ThemeContext } from 'styled-components';

import theme from '../theme';

const Modal = ({ children, title, visible, size, onClose: handleClose, ...rest }) => {
  const themeContext = useContext(ThemeContext);
  const animation = useSpring({
    display: visible ? 'flex' : 'none',
    opacity: visible ? 1 : 0,
    width: '100%',
  });

  const handleIgnore = ev => ev.stopPropagation();

  const handleKeydown = ev => {
    if (ev.type === 'keydown' && ev.key === 'Escape') {
      closeModal();
    }
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    handleClose();
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => document.removeEventListener('keydown', handleKeydown);
  }, []);

  return (
    <AnimatedWrapper style={animation} onClick={closeModal}>
      <Content {...rest} onClick={handleIgnore} size={size} activeTheme={themeContext}>
        <Header>
          <Title activeTheme={themeContext}>{title}</Title>
          <IconContainer onClick={closeModal} activeTheme={themeContext}>
            <Icon name="x" font="Feather" />
          </IconContainer>
        </Header>
        <Children>{children}</Children>
      </Content>
      <Overlay activeTheme={themeContext} />
    </AnimatedWrapper>
  );
};

Modal.displayName = 'Modal';

Modal.defaultProps = {
  children: null,
  title: '',
  visible: false,
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  onClose: PropTypes.func,
  title: PropTypes.string,
  visible: PropTypes.bool,
};

export default Modal;

const modalMargin = 30;

const Children = styled.div`
flex-direction: column;
margin: 0 5px 0;
height: auto;
flex: 1;
`;

const Content = styled.div`
background-color: ${({ activeTheme }) => activeTheme.modalBackground};
border-radius: ${theme.borderRadius}px;
box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
flex-direction: column;
margin-top: 100px;
min-height: 100px;

${({ size }) => size != 'small' ?
css`
width: 90%;
height: 100%;
max-height: 600px;
min-height: 400px;
`
:
css`
padding:20px;
`};
z-index: 1000;
overflow: auto;


@media (max-width: ${theme.breakpointTablet}px) {
width: 80%;
}
`;

const Header = styled.div`
align-items: center;
justify-content: space-between;
position: -webkit-sticky;
position: sticky;
top: 0px;
z-index: 99999;
background-color: inherit;
`;

const IconContainer = styled.div`
align-items: center;
cursor: pointer;
height: 60px;
justify-content: center;
width: 80px;
div {
i {
color: ${({ activeTheme }) => activeTheme.modalTitle} !important;
}
}
&:hover {
i {
opacity: .5;
}
}
`;

const Overlay = styled.div`
background-color: ${({ activeTheme }) => activeTheme.modalOpacity};
filter: blur(10px);
height: 100%;
margin: -5px;
position: absolute;
width: 100%;
z-index: 1;
`;

const Title = styled.h4`
font-size: 1.2rem;
font-weight: 300;
margin-left: ${modalMargin}px;
color: ${({ activeTheme }) => activeTheme.modalTitle};
`;

const Wrapper = styled.div`
align-items: flex-start;
bottom: 0;
justify-content: center;
left: auto;
position: fixed;
right: auto;
top: 0;
z-index: 9999;
`;

const AnimatedWrapper = animated(Wrapper);
