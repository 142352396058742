import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../theme';

class Content extends PureComponent {

  constructor(props) {
    super(props);
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <Wrapper hamburger={this.props.hamburger} {...rest}>
        <Scroll>{children}</Scroll>
      </Wrapper>
    );
  }
}

Content.defaultProps = {
  children: null,
  sidenav: true,
};

Content.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  sidenav: PropTypes.bool,
  hamburger: PropTypes.bool,
};

Content.displayName = 'Content';

export default Content;

const Scroll = styled.div`
display: block;
/* height: calc(100% - ${theme.contentVertPadding * 2}px);
overflow: auto; */
/* padding: ${theme.contentVertPadding}px ${theme.contentHorzPadding}px; */
padding: 5px 10px 20px;
position: relative;
/* width: calc(100% - ${theme.contentHorzPadding * 2}px); */
width: calc(100% - 40px);
`;

const Wrapper = styled.div`
background: ${({ activeTheme }) => (activeTheme === 'light' ? `${theme.bodyBackground}` : `${theme.bodyDarkBackground}`)};
/* bottom: 0;
left: ${({ sidenav, hamburger }) => (sidenav ? hamburger ? '40px' : `${theme.sidenavWidth}px` : 0)};
position: fixed;
right: 0;
/*top: ${theme.headerHeight}px;*/
top: 0;
z-index: 1;

div {
display: flex;
}

*::-webkit-scrollbar {
margin-right: 5px;
width: 8px;
}
*::-webkit-scrollbar-track {
background-color: ${theme.grey30};
border-radius: 10px;
margin-bottom: 3px;
margin-top: 3px;
}
*::-webkit-scrollbar-thumb {
background-color: ${theme.grey40};
border-radius: 10px;
margin: 4px;
-webkit-transition: background-color 200ms ease-in-out;
}
*:hover::-webkit-scrollbar-thumb {
background-color: ${theme.grey50};
border-radius: 10px;
margin: 4px;
}
`;
