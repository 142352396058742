import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Field from '../high-order/Field';

import theme from '../theme';

import { updateProperty } from 'qcp-js-ui-core/store/actions/rms';
import { getModel } from 'qcp-js-ui-core/models';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

const Radio = ({ index, label, enabled, binding: { id, property } }) => {
  const dispatch = useDispatch();
  const model = useSelector((state) => getModel(id, state.rms), shallowEqual);

  const value = model?.[property];

  const handleClick = () => dispatch(updateProperty(id, property, label));

  return (
    <Wrapper>
      <Option
        key={index}
        value={value}
        onClick={handleClick}
        disabled={!enabled}
        selected={value === model?.[property]}
      >
        {label}
      </Option>
    </Wrapper>
  );
};

Radio.propTypes = {
  index: PropTypes.number,
  label: PropTypes.string,
  enabled: PropTypes.bool,
  binding: PropTypes.object,
};
export default Radio;
export const FieldRadio = Field(Radio);

const Option = styled.div`
align-items: center;
background: ${({ selected }) => selected && theme.grey40};
cursor: pointer;
height: 100%;
justify-content: center;
text-transform: uppercase;
width: 100%;
&:not(:last-of-type) {
border-right: ${theme.inputBorder};
}
&:hover {
background: ${({ selected }) => !selected && theme.grey20};
}
${({ disabled }) =>
    disabled &&
css`
background: white !important;
cursor: not-allowed;
`}
`;

const Wrapper = styled.div`
flex: ${({ flex }) => flex};
flex-direction: column;
min-height: ${({ showLabel }) =>
    theme.wrapperHeight - Number(!showLabel) * theme.labelFullHeight}px;
padding: ${theme.componentPadding};
`;
