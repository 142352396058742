const colors = {
  royalBlue: '#1668C6',
  headerDarkBlue: '#0d3c72',
  gradientBlueDark: '#1668C6',
  gradientBlueLight: '#37A5FE',
  greyBox: '#f5f6f7',
  newOrange: '#E7663F',
  darkOrange: '#8f3a1a',
  //primary: '#0893d2',
  primary: '#f05a24',
  //default: '#1ab394',
  default: '#ffffff',

  //primaryDark: '#68b5ca',
  //primaryLight: '#9ccfde',
  primaryLight: '#f05a24',
  primaryDark: '#f05a24',
  sideBarBackground: '#284b91',
  sideBarActive: '#3d5d9c',
  sideBarButton: '#163066',
  bodyBackground: '#f5f9fa',
  bodyDarkBackground: '#363637',

  error: '#E1003B',

  orange: '#f05a24',
  orangeRgb: '240,90,36',
  orangeDark: '#e94e16',
  //orangeLinear: 'linear-gradient(180deg, rgba(240,90,36,0.10) 100%, rgba(240,90,36,0.10) 100%)';

  green: '#1ab394',
  // grey: '#e5e6e7'
  grey: 'hsl(0,0%,80%)',

  grey10: '#fafbfc',
  grey20: '#f4f5f7',
  grey30: '#ebecf0',
  grey40: '#dfe1e5',
  grey50: '#c1c7d0',
  grey60: '#a7a7a7',
  grey70: '#606060',
  tableBackground: '#c9c9c9',
  tableTheadBackground: '#646363',
  tabDarkBackground: '#464444',

  black: '#19191a',
  blue: '#284b91',
  blueLight: '#f8fafb',
  greywhite: '#f5f9fa',
  red: '#f02424',
  white: '#ffffff',

  disabledBackground: '#fbfbfb',
  disabledColor: '#999',

  uOfIBlue: '#13294b',
  uOfISecondary: '#0455a4',
  urbanaOrange: '#e84a27',
  iconColor: '#565656',
}
export const lightTheme = {
    body: colors.bodyBackground,
    text: '#363537',
    toggleBorder: '#FFF',
    background: '#363537',
    labelNIBRS: colors.royalBlue,
    label: colors.black,
    inputBackground: colors.white,
    inputText: colors.black,
    filter: 'invert(0)',
    filterButton: 'invert(0)',
    tableBackground: colors.white,
    tableTheadBackground: colors.blue,
    tableHeaderText: colors.white,
    tableHeaderStyle: '300',
    tableText: '#676767',
    tableBorder: colors.grey20,
    tableFilterBorder: colors.grey50,
    tabActiveBackground: colors.blue,
    tabBackground: colors.royalBlue,
    headerBackground: colors.royalBlue,
    logoOpacity: '1.0',
    logoHoverOpacity: '0.5',
    logoIconColor: colors.orange,
    iconColor: colors.iconColor,
    modalBackground: colors.white,
    modalOpacity: '#efefefaa',
    mainMenuDropdownBackground: colors.bodyBackground,
    highlighterLabel: colors.black,
    highlighterLabelEmpty: colors.grey60,
    highlighterValue: colors.royalBlue,
    highlighterBorder: colors.black,
    dropDownBackground: colors.greywhite,
    modalTitle: colors.black,
    disabledButton: 'lightgrey',
    disabledBorder: colors.orange,
    spinnerBackground: 'rgb(22 48 102 / 50%)',
    spinnerIcon: colors.orange,
    spinnerText: colors.black,
    fourEven: colors.royalBlue,
    fourOdd: colors.sideBarButton,
    fourError: colors.black,
    disabledPanel: colors.grey30,
    disabledStripe: colors.grey30,
    disabledStripe2: colors.grey40,
    divider: colors.newOrange,
}
export const darkTheme = {
    body: colors.bodyDarkBackground,
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    background: colors.bodyDarkBackground,
    labelNIBRS: '#5b96cb',
    label: '#8a8d93',
    inputBackground: colors.black,
    inputText: colors.greywhite,
    filter: 'invert(0.2)',
    filterButton: 'invert(1)',
    tableBackground: '#2d3035',
    tableTheadBackground: '#2d3035',
    tableHeaderText: '#676767',
    tableHeaderStyle: '600',
    tableText: '#8a8d93',
    tableBorder: '#383838',
    tableFilterBorder: colors.grey70,
    tabActiveBackground: colors.tableTheadBackground,
    tabBackground: colors.tabDarkBackground,
    headerBackground: colors.headerDarkBlue,
    logoOpacity: '0.5',
    logoHoverOpacity: '0.75',
    logoIconColor: colors.iconColor,
    iconColor: colors.bodyBackground,
    modalBackground: colors.bodyDarkBackground,
    modalOpacity: '#303030c4',
    mainMenuDropdownBackground: colors.tableBackground,
    highlighterLabel: '#7a7a7a',
    highlighterLabelEmpty: '#363637',
    highlighterValue: colors.greywhite,
    highlighterBorder: '#adadad',
    dropDownBackground: colors.tableBackground,
    modalTitle: '#8a8d93',
    disabledButton: '#2d3035',
    disabledBorder: '#707378',
    spinnerBackground: 'rgb(134 157 184 / 50%)',
    spinnerIcon: 'rgb(18 60 114)',
    spinnerText: '#363637',
    fourEven: '#676767',
    fourOdd: colors.grey60,
    fourError: colors.grey50,
    disabledPanel: '#2a2a2a',
    disabledStripe: '#222',
    disabledStripe2: '#333',
    divider: colors.grey40,
}