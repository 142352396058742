import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { clearNotification, setNotification } from '../../store/actions/notification';
import { requestStatus } from 'qcp-js-ui-core/constants';
import { addObjectListener } from 'qcp-js-ui-core/utils/errorBroadcast';
import store from '../../store';
import { logout } from '../../App';

import theme from '../theme';

addObjectListener((error) => {
    
    if(error.type == 'fetchError' && error.status == 202) {
      store.dispatch(setNotification('Could Not Save Data, But Will Continue Trying'));
    }
    if(error.type == 'fetchError' && error.status == 400) {
      store.dispatch(setNotification('Internal Error'));
    }
    if(error.type == 'fetchError' && error.status == 401) {
      store.dispatch(setNotification('Authorization Invalid'));
      const beginAgain = async () => {
        await store.dispatch(clearNotification());
        logout();
      }
      setTimeout(beginAgain, 2000);
    }
    if(error.type == 'fetchError' && error.status == 403) {
      store.dispatch(setNotification('Authorization Error'));
    }
    if(error.type == 'fetchError' && error.status == 404) {
      store.dispatch(setNotification('Server Does Not Know About Requested Data'));
    }
    if(error.type == 'fetchError' && error.status == 405) {
      store.dispatch(setNotification('Internal Error'));
    }
    if(error.type == 'fetchError' && error.status == 407) {
      store.dispatch(setNotification('A Proxy Has Determined You Are Unauthorized'));
    }
    if(error.type == 'fetchError' && error.status == 410) {
      store.dispatch(setNotification('The Requested Data Has Been Deleted From The Server'));
    }
    if(error.type == 'fetchError' && error.status == 500) {
      store.dispatch(setNotification('Internal Error'));
    }
    if(error.type == 'fetchError' && error.status == 502) {
      store.dispatch(setNotification('Upstream Error Behind Server'));
    }
    if(error.type == 'fetchError' && error.status == 504) {
      store.dispatch(setNotification('Server Timeout'));
    }
    
})

const Notification = ({ notificationStyle }) => {
  const dispatch = useDispatch();
  const message = useSelector(state => state.notification.message);
  const [ actionStatusId, setActionStatusId ] = useState('');
  const actionStatus = useSelector(state => state.rms.actionStatus);
  const { method: httpMethod, level } = actionStatus || {};
  let notificationTimer;

  const hasNewActionStatus = () =>
    actionStatus &&
actionStatus.status === requestStatus.FAIL &&
actionStatus.message &&
actionStatus.statusId !== actionStatusId;

  if(hasNewActionStatus()) {
    setActionStatusId(actionStatus.statusId);
    dispatch(setNotification(actionStatus.message));
  }

  const onDismiss = () => {
    dispatch(clearNotification());
    clearTimeout(notificationTimer);
  };

  const needsUserConfirmation = () => (
    (httpMethod && httpMethod.toLowerCase() !== 'get') ||
level === 'error'
  );

  useEffect(() => {
    if (message?.length) {
      clearTimeout(notificationTimer);
      if(!needsUserConfirmation()) {
        notificationTimer = setTimeout(onDismiss, 5000);
      }
    }
  }, [message]);

  if(!message?.length) { return null; }
  return <ReduxlessNotification
    notificationStyle={notificationStyle}
    message={message}
    onDismiss={onDismiss}
  />
}

export const ReduxlessNotification = ({notificationStyle, message, onDismiss}) => {
  return (
    <Wrapper className={notificationStyle}>
      <Text>{message}</Text>
      <X onClick={onDismiss}>&times;</X>
    </Wrapper>
  );
};

Notification.displayName = 'Notification';

Notification.propTypes = {
  message: PropTypes.string,
  notificationStyle: PropTypes.string
};

Notification.defaultProps = {
  message: '',
  notificationStyle: 'alert'
};

export default Notification;

const Wrapper = styled.div`
border: 1px solid transparent;
border-radius: 5px;
justify-content: space-between;
position: fixed;
right: 5px;
top: 60px;
width: 200px;
z-index: 100;

&.alert {
color: ${theme.alertColors.text};
background-color: ${theme.alertColors.background};
border-color: ${theme.alertColors.border};
}

&.info {
color: ${theme.infoColors.text};
background-color: ${theme.infoColors.background};
border-color: ${theme.infoColors.border};
}

&.success {
color: ${theme.successColors.text};
background-color: ${theme.successColors.background};
border-color: ${theme.successColors.border};
}

&.warning {
color: ${theme.warningColors.text};
background-color: ${theme.warningColors.background};
border-color: ${theme.warningColors.border};
}
`;

const Text = styled.p`
margin: 15px;
`;

const X = styled.span`
margin: 5px;
line-height: 5px;
cursor: pointer;
`;
