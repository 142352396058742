import { navigateSet } from 'qcp-js-ui-core/store/actions/navigator';

const DISPLAY_UPLOAD_FILE = 'DISPLAY_UPLOAD_FILE';
const HIDE_UPLOAD_FILE = 'HIDE_UPLOAD_FILE';

export const displayModal = (modalType, contents) => ({
  type: DISPLAY_UPLOAD_FILE,
  modalType,
  contents,
});

export const hideModal = () => {
  return async (dispatch, getState) => {
    const currentRoute = getState().navigator?.currentRoute || [];
    const withoutModals = currentRoute.filter(value => !value.isModal);
    await dispatch(navigateSet(withoutModals));
    await dispatch({ type: HIDE_UPLOAD_FILE });
  };
};