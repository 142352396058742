import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';

import { getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { updateProperty } from 'qcp-js-ui-core/store/actions/rms';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import theme from '../theme';

const Checkbox = ({ label, enabled, binding }) => {
  const { id, property } = binding;
  const dispatch = useDispatch();
  const value = useSelector((state) => (
    getValueFromBindingObject(binding, state)
  ), shallowEqual);
  const invertBoundValue = () => {
    dispatch(updateProperty(id, property, !value));
  }

  return LogiclessCheckbox({ value, enabled, label, handleClick: invertBoundValue });
};

const LogiclessCheckbox = ({ value, enabled = true, label = '', handleClick = (() => null) }) => {
    const themeContext = useContext(ThemeContext);
  const [stateValue, updateValue] = useState(value);
  const onClick = () => {
    if(!enabled) { return; }
    updateValue(!stateValue);
    handleClick();
  };

return (
  <Wrapper disabled={!enabled} label={label}>
    <CheckboxContainer tabIndex="0" onClick={onClick} onKeyPress={(event) => {
      event.key === ' ' && onClick();
      return event.key === ' ';
    }}>
      <Span value={value} stateValue={stateValue}>{(value || stateValue) && <SpanChild />}</Span>
      <Text activeTheme={themeContext}>{label}</Text>
    </CheckboxContainer>
  </Wrapper>
);
}

LogiclessCheckbox.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  enabled: PropTypes.bool,
  stateValue: PropTypes.bool,
  value: PropTypes.bool,
};

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  label: PropTypes.string,
  binding: PropTypes.object,
};

export default Checkbox;
export { LogiclessCheckbox };

const leftPadding = 25;

const SpanChild = styled.span`
background: ${theme.orange};
position: absolute;
top: 0px;
width: 21px;
height: 21px;
border-radius: 2px;
z-index: 1;
`;

const Span = styled.span`
border: 1px solid ${({ stateValue }) => (stateValue ? theme.orange : theme.grey)};
border-radius: 2px;
height: 20px;
margin-right: 10px;
position: absolute;
width: 20px;
&:hover {
border: 1px solid ${theme.primary};
}
&:before {
background: ${theme.default};
}
&:active {
background: ${theme.orange};
}
&:after {
border: 1px solid ${theme.white};
border-width: 0 3px 3px 0;
display: ${({ stateValue }) => (stateValue ? 'flex' : 'none')};
content: '';
height: 10px;
left: 6px;
position: absolute;
top: 2px;
transform: rotate(45deg);
width: 5px;
z-index: 2;
& > span {
visibility: visible;
}
}
`;

/* height should be the input height plus it's padding
plus it's border (default 1px top and bottom, 2px total) */
const CheckboxContainer = styled.div`
height: ${theme.inputHeight + theme.inputPadding * 2 + 2}px;
align-items: center;
`;

const Text = styled.span`
padding-left: ${leftPadding + 4}px;
user-select: none;
color: ${({ activeTheme }) => activeTheme?.label};
`;

Text.displayName = 'Text';

const Wrapper = styled.div`
align-items: flex-start;
cursor: pointer;
min-height: 40px;
position: relative;
/*top: 15px;*/
justify-content: center;
display: flex;
/*flex: ${({ flex }) => flex};*/
flex-wrap: wrap;
padding: ${theme.componentPadding};
flex-direction: column;
${({ disabled }) =>
    disabled &&
css`
cursor: ${({ label }) => label == 'Completed' ? 'pointer' : 'not-allowed'};
> span {
color: ${theme.grey50};
font-style: italic;
font-weight: 100;
}
`}
`;
