import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import theme from '../theme';

const ProgressBar = props => {
  const { value, max, width } = props;
  const themeContext = useContext(ThemeContext);
  return (
    <Wrapper width={width} activeTheme={themeContext}>
      <progress value={value} max={max} />
      {(value !== 0 && max !== 0) &&<span>{((value / max) * 100).toFixed(2)}%</span>}
    </Wrapper>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
  width: PropTypes.string,
};

ProgressBar.defaultProps = {
  max: 100,
  color: theme.sideBarBackground,
  width: '150px',
};

const Wrapper = styled.div`
color: ${({ activeTheme }) => activeTheme.modalTitle};

progress {
height: 20px;
align-self: center;
margin: 0 10px 0 10px;
}

progress[value] {
width: ${props => props.width};
appearance: none;
color: ${theme.primaryLight};
}

progress::-webkit-progress-bar {
height: 1.25rem;
border-radius: 10px;
background-color: ${theme.primaryLight};
}

progress::-webkit-progress-value {
height: 1.25rem;
border-radius: 10px;
background-color: ${theme.sideBarBackground};
}
`;
export default ProgressBar;
