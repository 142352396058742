import React, {useContext, useState} from 'react';
import { useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { StyledTable } from '../shared/Table';
import Icon from '../shared/Icon';

import theme from '../theme';

const RequestsTable = ({requests}) => {
  const themeContext = useContext(ThemeContext);
  const data = requests.map(({url}, index) => ({ url, key: `url-${index}` }));
  return <StyledTable
    columns={[{
      title:'Outstanding Request URLs',
      dataIndex: 'url',
      key: 'url',
    }]}
    data={data}
    activeTheme={themeContext}
  />
}

const OutstandingRequests = () => {
  const requests = useSelector(({ workerRequests: { outstandingRequests } }) => outstandingRequests);
  const themeContext = useContext(ThemeContext);
  const requestsList = RequestsTable({requests})
  const [tableVisible, setTableVisible] = useState(false);
  const showTable = () => { setTableVisible(true) };
  const hideTable = () => { setTableVisible(false) };

  if(!requests?.length) {
    return null;
  }

  const requestTable = <RequestsTable requests={requests}/>
  return <div onMouseOver={showTable} onMouseLeave={hideTable}>
    <Notice activeTheme={themeContext}>
      <Count>{requests.length}</Count>
      <Icon color={themeContext.text} name="refresh-cw" size={16} />
    </Notice>
    {tableVisible && <TableContainer activeTheme={themeContext}>
      <RequestsTable requests={requests}/>
    </TableContainer>}
  </div>;
}

const TableContainer = styled.div`
position: fixed;
top: 50px;
right: 200px;
`;

const Count = styled.div`
padding: 0 5px;
`;

const Notice = styled.div`
border-width: 0px;
border-radius: 20px;
background-color: ${({activeTheme}) => activeTheme.tableBackground};
position: relative;
top: 2px;
height: 5px;
width: 30px;
align-items: center;
font-weight: inherit;
letter-spacing: 1px;
justify-content: center;
text-transform: uppercase;
padding: 10px;
margin: 0 10px;
color: ${({activeTheme}) =>  activeTheme.text};
`;

export default OutstandingRequests;
