import React from 'react'
import { func, string } from 'prop-types';
import styled, { css } from "styled-components"
import Icon from './shared/Icon'
import theme from './theme'

const Toggle = ({theme,  toggleTheme }) => {
    return(
        <Wrapper onClick={toggleTheme}>
            <Container activeTheme={theme}>
                {
                    theme === 'dark' ?
                    <Checkbox className="checked">
                        <Icon
                            name="moon"
                            font="Feather"
                            size={16}
                            color={'#f5f6f7'}
                        />
                    </Checkbox>
                :
                    <Checkbox className="unchecked">
                        <Icon
                            name="sun"
                            font="Feather"
                            size={16}
                            color={'#E7663F'}
                        />
                    </Checkbox>
                }
            </Container>
            <Circle className={`${theme === 'light' ? 'left' : 'right'}`}></Circle>
            <Input type="checkbox" aria-label="Toggle Button" />
        </Wrapper>
    )
};
Toggle.propTypes = {
    theme: string.isRequired,
    toggleTheme: func.isRequired,
}
export default Toggle;

const Wrapper = styled.div`
touch-action: pan-x;
display: inline-block;
position: relative;
cursor: pointer;
background-color: transparent;
border: 0;
padding: 0;
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
-webkit-tap-highlight-color: rgba(0,0,0,0);
-webkit-tap-highlight-color: transparent;
margin-right: 20px;
`

const Input = styled.input`
border: 0;
clip: rect(0 0 0 0);
height: 1px;
margin: -1px;
overflow: hidden;
padding: 0;
position: absolute;
width: 1px;
`

const Circle = styled.div`
transition: all .5s cubic-bezier(.23,1,.32,1) 0ms;
position: absolute;
top: 1px;
width: 22px;
height: 22px;
border: 1px solid #4d4d4d;
border-radius: 50%;
box-sizing: border-box;
transition: all .25s ease;

&.right {
    right: 1px;
    background-color: ${theme.bodyBackground};
}
&.left {
    left: 1px;
    background-color: ${theme.bodyDarkBackground};
}
`

const Container = styled.div`
width: 50px;
height: 24px;
padding: 0;
border-radius: 30px;
background-color: ${({ activeTheme }) => activeTheme === 'light' ? theme.bodyBackground : theme.bodyDarkBackground};
transition: all .2s ease;
`

const Checkbox = styled.div`
position: absolute;
width: 10px;
height: 10px;
top: 2px;
bottom: 0;
margin-top: auto;
margin-bottom: auto;
line-height: 0;
opacity: 0;
transition: opacity .25s ease;

&.checked {
    opacity: 1;
    left: 8px;
}
&.unchecked {
    opacity: 1;
    right: 10px;
}
`

