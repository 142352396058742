import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

import { useDispatch } from 'react-redux';
import { displayModal } from '../../store/actions/modal';

const ModalButton = ({ viewName, binding, label, weight, enabled, setShowLoading} ) => {
  const { id, property } = binding;
  const dispatch = useDispatch();

  const popupModal = () => {
    dispatch(displayModal('schemaView', { id, property, title: label, viewName, weight, setShowLoading }));
  };

  return <Button
    disabled={!enabled}
    dropdown={false}
    label={label}
    onPress={popupModal}
  />;
};

ModalButton.displayName = 'ModalButton';

ModalButton.defaultProps = {
  className: '',
  disabled: false,
  icon: null,
  id: null,
  loading: false,
  title: null,
  type: 'default',
  onClick: () => {},
};

ModalButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  dropdown: PropTypes.bool,
  id: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  viewName: PropTypes.string,
  binding: PropTypes.object,
  label: PropTypes.string,
  weight: PropTypes.number,
  enabled: PropTypes.bool,
  setShowLoading: PropTypes.func,
};

export default ModalButton;
