import React from 'react';

import Divider from './shared/Divider';
import Title from './shared/Title';
import Button from './shared/Button';
import Table from './shared/Table';
import Spinner from './shared/Spinner';
import TypeSwitch from './shared/TypeSwitch';
import List from './high-order/List';
import ModalButton from './shared/ModalButton';
import BoundImage from './shared/BoundImage';
import Image from './shared/Image';
import DropDown from './form/DropDown';
import Checkbox from './form/Checkbox';
import Input from './form/Input';
import Height from './form/HeightComponent';
import TextSearch from './form/TextSearch';
import DateTimePicker from './form/DateTimePicker';
import Panel from './high-order/Panel';
import TabPane from './high-order/TabPane';
import Filter from './shared/Filter';
import Radio from './form/Radio';
import ViewComponent from './ViewComponent';
import store from '../store';

import Highlighter from './shared/Highlighter';
import { HighlighterText } from './shared/Highlighter';

// TODO: implement what these log and delete this method
const logCalls = (object, ...names) =>
  names.forEach((name) => object[name] = (...args) => console.log(name, args));

const getDivider = ({ index, visible }) => <Divider key={index} visible={visible} />;

const getBoundTitle = ({ index, binding }) => <Title
  key={index}
  value={binding}
/>;

const getSpinner = () => <Spinner
  className={'loading'}
  loading={true}
/>;

const getFilter = ({ index, active, filters, onChange }) => <Filter
  index={index}
  active={active}
  names={filters}
  onChange={onChange}
/>;

const getImage = ({ index, file }) => <Image
  key={index}
  file={file}
/>;

const getTitle = ({ index, text}) => <Title
  key={index}
  value={text}
/>;

// TODO: look at local front end to better figure out what needs to be in things like className
const getButton = ({index, label, onPress, enabled, hAlign, icon, iconType}) => <Button
  key={index}
  passed={index}
  id={label}
  label={label}
  onPress={onPress}
  className={label.toLowerCase().includes('image') ? '' : ''}
  enabled={enabled}
  icon={icon}
  iconType={iconType}
  loading={false}
  type="primary"
  hAlign={hAlign}
/>;

const getDateTimePicker = ({ index, label, mode, binding, future, enabled }) => <DateTimePicker
  key={index}
  label={label}
  enabled={enabled}
  mode={mode}
  future={future}
  binding1={binding}
/>;

const getDropdown = ({
  index,
  generateEngine,
}) => <DropDown
  key={index}
  generateEngine={generateEngine}
/>;

const getList = ({ index, listKey, binding, template, setShowLoading }) => <List
  key={index}
  listKey={listKey}
  binding={binding}
  template={template}
  setShowLoading={setShowLoading}
/>;

const getMultiValuePicker = ({ index, label, enabled, onLoad, displayProperty, binding }) => <DropDown
  key={index}
  label={label}
  onLoad={onLoad}
  enabled={enabled}
  isMultiSelect={true}
  displayProperty={displayProperty}
  binding={binding}
/>;

const getRadioGroup = ({ index, label, enabled, binding }) => <Radio
  key={index}
  binding={binding}
  enabled={enabled}
  label={label}
/>;

const getBoundText = ({ index, label, binding, json }) =>
  binding ? (
    <Highlighter key={index} label={label} binding={binding} json={json} />
  ) : (
    <HighlighterText key={index} label={label} value={json.value} />
  );

const getText = ({ index, label, text }) => <Highlighter
  key={index}
  label={label}
  value={text}
/>;

const getTextBox = ({ index, binding, generateEngine }) => <Input
  key={`${JSON.stringify(binding)}${index}`}
  binding={binding}
  generateEngine={generateEngine}
/>;

const getInput = ({ generateEngine }) => getTextBox({ generateEngine });

const getHeightComponent = ({ index, label, enabled, generateEngine }) => <Height
  key={`${label}${enabled}${index}`}
  generateEngine={generateEngine}
/>;

const getHeight = ({ generateEngine }) => getHeightComponent({ generateEngine });

const getSearch = () => null;

const getCheckbox = ({index, enabled, label, binding}) => <Checkbox
  key={index}
  label={label}
  enabled={enabled}
  binding={binding}
/>;

const getTabPane = ({ index, listKey, id, data, direction, navigation }) => <TabPane
  key={index}
  listKey={listKey}
  id={id}
  data={data}
  direction={direction}
  navigation={navigation}
/>;

const getModalButton = ({index, enabled, viewName, binding, label, weight, setShowLoading }) => <ModalButton
  key={index}
  viewName={viewName}
  binding={binding}
  enabled={enabled}
  label={label}
  weight={weight}
  setShowLoading={setShowLoading}
/>;

function getHashCode(text) {
  let hash = 0, character;
  for (let i = 0; i < text.length; i++) {
    character = text.charCodeAt(i);
    hash = ((hash << 5) - hash) + character;
    hash |= 0;
  }
  return hash;
}

const getTable = ({ binding, columns, selectionMode, rowContexts, generateEngine }) => {
  const tableDescription = { binding, columns, selectionMode, rowContexts };
  const key = getHashCode(JSON.stringify(tableDescription));
  return <Table
    key={key}
    generateEngine={generateEngine}
  />;
};

const getBoundImage = ({ index, binding, fileType }) => <BoundImage
  key={index} binding={binding} fileType={fileType}
/>;

const getTextSearch = ({
  index,
  id,
  enabled,
  fallbackContextAction,
  label,
  binding,
  autoSearch,
  searchAction,
}) => <TextSearch
  key={index}
  id={id}
  label={label}
  fallbackContextAction={fallbackContextAction}
  enabled={enabled}
  binding={binding}
  autoSearch={autoSearch}
  searchAction={searchAction}
/>;

const getView = ({ index, id: quin, name: key, setShowLoading, enabled }) => {
  const link = store.getState().rms.schemas.views[key]?.path?.replace(/{\w+}/, quin);
  const route = { quin, key, link };
  return <ViewComponent enabled={enabled} key={index} route={route} setShowLoading={setShowLoading} />;
};

const getPanel = ({ index, flexDirection, content, collapsible, highlightSeverity, rowDivisions, weight, enabled, alignItems }) => <Panel
  key={index}
  highlightSeverity={highlightSeverity}
  flexDirection={flexDirection}
  content={content}
  rowDivisions={rowDivisions}
  collapsible={collapsible}
  weight={weight}
  enabled={enabled}
  alignItems={alignItems}
/>;

const getTypeSwitch = ({
  index, id, typeOptionsBinding, childContext, selectedTypeBinding, rowDivisions,
}) => <TypeSwitch
  key={index}
  id={id}
  typeOptionsBinding={typeOptionsBinding}
  selectedTypeBinding={selectedTypeBinding}
  childContext={childContext}
  rowDivisions={rowDivisions}
/>;

const ViewComponentFactory = {
  getRadioGroup,
  getSpinner,
  getImage,
  getPanel,
  getFilter,
  getCheckbox,
  getTable,
  getButton,
  getTextBox,
  getInput,
  getHeight,
  getSearch,
  getHeightComponent,
  getBoundText,
  getDropdown,
  getList,
  getModalButton,
  getMultiValuePicker,
  getDateTimePicker,
  getBoundImage,
  getTabPane,
  getBoundTitle,
  getTypeSwitch,
  getTitle,
  getText,
  getTextSearch,
  getView,
  getDivider,
};

logCalls(
  ViewComponentFactory,
  'getNotFoundPage',
  'getActivityIndicator',
  'getImage',
  'getDateTimeRangePicker',
  'getProgress',
  'getTree',
);

export default ViewComponentFactory;

