const SET_NOTIFICATION = 'SET_NOTIFICATION';
const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
const ADD_FILTER_OUT = 'ADD_FILTER_OUT';
const REMOVE_FILTER_OUT = 'REMOVE_FILTER_OUT';

export const setNotification = (message) => ({
  type: SET_NOTIFICATION,
  message,
});

export const clearNotification = () => ({
  type: CLEAR_NOTIFICATION,
});

export const removeFilterOut = (message) => ({
    type: REMOVE_FILTER_OUT,
    message,
});

export const addFilterOut = (message) => ({
  type: ADD_FILTER_OUT,
  message,
});
