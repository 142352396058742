import styled, { css } from 'styled-components';

import theme from './theme';

const Center = styled.div`
align-items: center;
display: flex;
flex-direction: column;
height: 100%;
justify-content: center;
width: 100%;
`;

const Col = styled.div`
flex: ${({ basis = 0, grow = 1, shrink = 1 }) =>
    `${grow} ${shrink} ${basis}`};
@media (max-width: ${theme.breakpointTablet}px) {
flex: 1 0 100%;
}
`;

const Full = styled.div`
flex: 1;
margin: 12px 0;
min-height: 60px;
`;

const H4 = styled.h4`
font-size: 1.2rem;
font-weight: 300;
/* height: 20px; */
margin: 24px 0;
white-space: nowrap;
`;

const H5 = styled.h5`
font-size: 1.3rem;
font-weight: 100;
height: 24px;
margin: 16px 0;
`;

const Half = styled(Full)`
width: 50%;
`;

const InnerContent = styled.div`
flex-direction: column;
width: 100%;
`;

const Label = styled.label`
font-size: .75rem;
height: ${theme.labelHeight}px;
margin-bottom: ${theme.labelMargin}px;
${({ disabled }) =>
    disabled &&
css`
color: ${theme.disabledColor};
font-style: italic;
`}
${({ error }) =>
    error &&
css`
color: ${theme.error};
margin-bottom: 0;
`}
`;

const Optional = styled.span`
color: ${theme.grey};
font-size: .7rem;
font-style: italic;
`;

const Quarter = styled(Full)`
width: 25%;
`;

const Row = styled.div`
flex-direction: row;
flex-flow: row wrap;
width: 100%;

@media (max-width: ${theme.breakpointTablet}px) {
margin: 0 -5px;
}
`;

const TabPaneRow = styled(Row)`
flex-direction: column;
`;

const PanelRow = styled.div`
flex-direction: row;
width: 100%;
margin: 0;
padding: 0
@media (max-width: ${theme.breakpointTablet}px) {
margin: 0 -5px;
}
`;

const Divider = styled.hr`
width: 100%;
border-top: 1px solid ${theme.newOrange};
border-bottom: 0;
margin: 10px 0 10px 0;
`;

export default {
  Center,
  Col,
  Full,
  H4,
  H5,
  Half,
  InnerContent,
  Label,
  Optional,
  Quarter,
  Row,
  TabPaneRow,
  PanelRow,
  Divider,
};
export {
  Center,
  Col,
  Full,
  H4,
  H5,
  Half,
  InnerContent,
  Label,
  Optional,
  Quarter,
  Row,
  TabPaneRow,
  PanelRow,
  Divider,
};
