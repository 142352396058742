import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TopNavigation from './components/layout/TopNavigation';
import logo from '../static/quicket.svg';

const TopNavigationSystem = ({hamburger, hamburgerClick}) => {
  const mainMenu = useSelector((state) => state.rms?.mainMenu);
  const { pathname } = useLocation();
  const viewPaths = useSelector((state) => state.rms?.schemas?.views?.byPath);

  const handleMain = () => {
    // this location will need to be changed
    // must confirm a "Home" link is necessary, and where it should go
    window.location = window.qcpConfig.oneappUrl + '/' + window.qcpConfig.clientName + '/home/index';
  };

  const handleClick = (hamburger) => {
    hamburgerClick(hamburger);
  };

  const routes = [];
  const bottomRoutes = [
    {
      icon: 'CornerUpLeft',
      name: 'Home',
      route: window.qcpConfig.oneappUrl + '/' + window.qcpConfig.clientName + '/home/index/',
      type: 'anchor',
      active: false,
      external: true,
      onClick: handleMain,
    },
  ];

  if(mainMenu) {
    let mainMenuItems = mainMenu.map(({ action, label, icon, subItems }) => ({
      action: JSON.stringify(action),
      label,
      path: action?.link ? action.link.href : label,
      icon: icon || 'File',
      subItems: subItems?.length && subItems.map(({ action, label }) => ({
        action: JSON.stringify(action),
        label,
        path: action?.link ? action.link.href : label,
      }))
    }));

    if(mainMenuItems) {
      mainMenuItems.forEach(({ label, path, action, icon, subItems }) => {
        let newAction = action && action.replace('navigateToIncident', 'navigateToRoot');
        routes.push({
          icon: icon,
          name: label,
          active: pathname === path ? true : false,
          type: 'link',
          route: path,
          external: false,
          onClick: handleMain,
          rootView: viewPaths[path === '/' ? path : path.replace('/', '')],
          action: newAction && JSON.parse(newAction.replace(/&#34;/g, '"')),
          subItems: subItems?.length && subItems.map(({ label, path, action }) => ({
            action: JSON.parse(action.replace(/&#34;/g, '"')),
            name: label,
            route: path,
            rootView: viewPaths[path === '/' ? path : path.replace('/', '')],
          }))
        });
      });
      if(pathname === '/') routes[0].active = true;
    }
  }

  return <TopNavigation logo={logo} items={routes} bottomItems={bottomRoutes} hamburger={hamburger} hamburgerClick={handleClick} />;
};

export default TopNavigationSystem;
