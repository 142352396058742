import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { InputComponent } from './Input';
import { useDispatch } from 'react-redux';
import styled, { css, ThemeContext } from 'styled-components';
import getHighlightSeverityColor from '../../utils/highlight-severity-color';
import theme from '../theme';

import Select from '../shared/Select';
import { Col } from '../common';

const useFocus = () => {
  const ref = useRef(null);
  const setFocus = () => {setTimeout(() => ref.current?.focus(), 0);};
  return [ ref, setFocus ];
};

const Dropdown = ({
  generateEngine,
}) => {
  const themeContext = useContext(ThemeContext);
  const [dropdown, setDropdown] = useState({});
  const [isArbitrary, setIsArbitrary] = useState();
  const [shouldAutoFocus, setShouldAutoFocus] = useState(false);
  const [displayed, setDisplayed] = useState(false);
  const [failedOptionsLoad, setFailedOptionsLoad] = useState(false);
  const dispatch = useDispatch();
  const setArbitrary = (show) => {
    setShouldAutoFocus(!show);
    setIsArbitrary(show);
  };
  const { label, enabled, multiSelect, highlightSeverity, weight, hasSpinner, arbitrary, isLoading } = dropdown;
  useEffect(() => generateEngine({
    setDropdown,
    setArbitrary,
    dispatch,
  }), []);

  const enterValue = (event) => dropdown.enterValue(event?.target?.value);

  const showToolTip = () => {
    setDisplayed(true);
  };  

  const hideToolTip = () => {
    setDisplayed(false);
  }

  let failedOptionsTimerId;
  const optionsFailedToLoad = () => {
    setFailedOptionsLoad(true);
    clearTimeout(failedOptionsTimerId);
  }

  if(isLoading && !failedOptionsLoad) {
    failedOptionsTimerId = setTimeout(() => {
      optionsFailedToLoad()
    }, 45000);
  }

  useEffect((() => {
    document.body.addEventListener('click', hideToolTip)
  }), [])

  return (
    <DropdownColumn activeTheme={themeContext} weight={weight} highlightSeverity={highlightSeverity}>
      { !isArbitrary && <Select
        shouldAutoFocus={shouldAutoFocus}
        disabled={!enabled}
        label={label}
        allowsArbitrary={dropdown.allowsArbitrary}
        value={Array.isArray(dropdown.selected) ? dropdown.selected : [dropdown.selected]}
        options={dropdown.options}
        multiple={multiSelect}
        onChange={dropdown.selectValue}
        onInputChange={dropdown.handleSearchTextChange}
        isNIBRS={dropdown.isNibrsField}
        tooltip={dropdown.tooltip}
        showToolTip={showToolTip}
        displayTooltip={displayed}
        activeTheme={themeContext}
        loading={hasSpinner ? isLoading : false}
        failedLoad={failedOptionsLoad}
      />
      }
      {
        isArbitrary && <InputComponent
          enabled={enabled}
          showLabel={true}
          label={label}
          isMultiLine={false}
          displayValue={dropdown.arbitraryValue}
          handleTextChange={enterValue}
          rowCount={1}
          showError={false}
        />
      }
    </DropdownColumn>
  );
};

Dropdown.propTypes = {
  generateEngine: PropTypes.func,
};

const getBorder = ({highlightSeverity}) => {
  if(!highlightSeverity) { return undefined; }
  const lastIndex = theme.inputBorder.lastIndexOf(' ');
  return `${
    theme.inputBorder.substring(0, lastIndex) 
  } ${
    getHighlightSeverityColor({highlightSeverity}) 
  }`;
};

const DropdownColumn = styled(Col)`
border: ${getBorder};
${({ weight }) =>
    weight &&
css`
flex: ${weight};
`}
input {
background: ${({ activeTheme }) => activeTheme?.inputBackground};
color: ${({ activeTheme }) => activeTheme?.inputText};
filter: ${({ activeTheme }) => activeTheme?.filter};
}
label {
color: ${({ activeTheme }) => activeTheme?.label};
filter: ${({ activeTheme }) => activeTheme?.filter};
}
`;
export default Dropdown;
