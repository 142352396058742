
import { navigateReset } from 'qcp-js-ui-core/store/actions/navigator';
import store from '../store';

const hookNavigation = async (link) => {
  if(link?.href?.startsWith('/')) {
    await store.dispatch(navigateReset());
  }
};

export default hookNavigation;
