import React, { memo, useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { handleActions } from 'qcp-js-ui-core/actions';
import { FILTER_VALUES } from 'qcp-js-ui-core/constants';
import { updateProperty } from 'qcp-js-ui-core/store/actions/rms';
import Navigation from '../../implementation/navigation';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';

import theme from '../theme';

const Filter = ({
  names = [],
}) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(names[0]['label']);
  const [currFilter, setCurrFilter] = useState([names[0]['filter']]);
  const currentRoutes = useSelector(store => store.navigator?.currentRoute || [{}]);
  const themeContext = useContext(ThemeContext);

  const handleChange = (filter, label) => {
    setActive(label);
    setCurrFilter([filter]);
  };

  useEffect(() => {
    // we need to pass a quin with the action, so grab the current quin from the current route
    if(currFilter.length > 0) {
      dispatch(updateProperty(currentRoutes[0].quin, FILTER_VALUES, null));
      dispatch(
        handleActions({
          json: currFilter,
          quin: currentRoutes[0].quin,
          navigation: Navigation,
        }),
      );
    } else {
      dispatch(updateProperty(currentRoutes[0].quin, FILTER_VALUES, null));
    }

  }, [currFilter]);

  return (
    <Wrapper>
      {names.map(({ filter, label }, index) => (
        <Tab
          key={index}
          active={active.length > 0 && label === active}
          activeTheme={themeContext}
        >
          <span onClick={() => handleChange(filter, label)}>{label || 'unnamed'}</span>
        </Tab>
      ))}
    </Wrapper>
  );
};

Filter.displayName = 'Filter';

Filter.propTypes = {
  active: PropTypes.string,
  names: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      filter: PropTypes.string.isRequired,
    }),
  ),
  onChange: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => {
  const { active: active = '', names: names = [] } = prevProps;
  const { active: nextActive = '', names: nextNames = [] } = nextProps;

  if (active !== nextActive || names.length !== nextNames.length) {
    return false;
  }

  for (let i = 0; i < names.length; i++) {
    if (names[i] !== nextNames[i]) {
      return false;
    }
  }

  return true;
};

export default memo(Filter, areEqual);
export { areEqual };

export const Tab = styled.div.attrs(({ active }) => ({
  className: active && 'active',
}))`
align-items: center;
color: #999;
display: flex;
font-weight: 300;
justify-content: center;
letter-spacing: ${theme.letterSpacing}px;
text-decoration: none;
width: 100%;
border-radius: 10px 10px 0 0;
background-color: ${({ activeTheme }) => activeTheme.tabBackground};
margin-right: 1px;
padding-bottom: 5px;

&:hover {
    background-color: ${({ activeTheme }) => activeTheme.tabActiveBackground};
}
span {
text-transform: uppercase;
padding-top: 5px;
color: ${theme.bodyBackground};

&:hover {
cursor: pointer;
color: ${theme.white};
}
}

&.active {
    background-color: ${({ activeTheme }) => activeTheme.tabActiveBackground};
    border-bottom: 2px solid ${theme.newOrange};
    span {
        color: ${theme.white};
        font-weight: 600;
        cursor: pointer;
    }
}
}

`;

export const Wrapper = styled.div`
justify-content: space-evenly;
width: 85%;
`;