const colors = {
  royalBlue: '#1668C6',
  royalBlueRgb: '22,104,198',
  gradientBlueDark: '#1668C6',
  gradientBlueLight: '#37A5FE',
  greyBox: '#f5f6f7',
  newOrange: '#E7663F',
  //primary: '#0893d2',
  primary: '#f05a24',
  //default: '#1ab394',
  default: '#ffffff',

  //primaryDark: '#68b5ca',
  //primaryLight: '#9ccfde',
  primaryLight: '#f05a24',
  primaryDark: '#f05a24',
  sideBarBackground: '#284b91',
  sideBarActive: '#3d5d9c',
  sideBarButton: '#163066',
  bodyBackground: '#f5f9fa',
  bodyDarkBackground: '#363537',

  error: '#E1003B',

  orange: '#f05a24',
  orangeRgb: '240,90,36',
  orangeDark: '#e94e16',
  //orangeLinear: 'linear-gradient(180deg, rgba(240,90,36,0.10) 100%, rgba(240,90,36,0.10) 100%)';

  green: '#1ab394',
  // grey: '#e5e6e7'
  grey: 'hsl(0,0%,80%)',

  grey10: '#fafbfc',
  grey20: '#f4f5f7',
  grey30: '#ebecf0',
  grey40: '#dfe1e5',
  grey50: '#c1c7d0',
  grey60: '#8d8d8d',

  black: '#19191a',
  blue: '#284b91',
  blueLight: '#f8fafb',
  greywhite: '#f5f9fa',
  red: '#f02424',
  white: '#ffffff',

  disabledBackground: '#fbfbfb',
  disabledColor: '#999',

  uOfIBlue: '#13294b',
  uOfISecondary: '#0455a4',
  urbanaOrange: '#e84a27',
  iconColor: '#565656',

  warning: {
    text: '#856404',
    background: '#fff3cd',
    border: '#ffeeba',
  },

  alert: {
    text: '#721c24',
    background: '#f8d7da',
    border: '#f5c6cb',
  },

  info: {
    text: '#004085',
    background: '#cce5ff',
    border: '#b8daff',
  },

  success: {
    text: '#155724',
    background: '#d4edda',
    border: '#c3e6cb',
  },
};

const standard = {
  border: `1px solid ${colors.grey}`,
  borderRadius: 4,

  labelHeight: 11,
  labelMargin: 5,
  componentPadding: '0 5px',
  baseFont: 'Open Sans',
};

const theme = {
  baseFont: standard.baseFont,
  primary: colors.primary,
  primaryDark: colors.primaryDark,
  primaryLight: colors.primaryLight,
  accent: colors.urbanaOrange,
  default: colors.default,
  submit: colors.orange,
  sideBarBackground: colors.sideBarBackground,
  sideBarActive: colors.sideBarActive,
  sideBarButton: colors.sideBarButton,
  bodyBackground: colors.bodyBackground,
  bodyDarkBackground: colors.bodyDarkBackground,

  royalBlue: colors.royalBlue,
  royalBlueRgb: colors.royalBlueRgb,
  gradientBlueLight: colors.gradientBlueLight,
  gradientBlueDark: colors.gradientBlueDark,
  greyBox: colors.greyBox,
  newOrange: colors.newOrange,

  iconColor: colors.iconColor,

  orange: colors.orange,
  orangeRgb: colors.orangeRgb,
  orangeDark: colors.orangeDark,
  greywhite: colors.greywhite,
  red: colors.red,
  white: colors.white,
  black: colors.black,
  blue: colors.blue,
  blueLight: colors.blueLight,

  error: colors.error,

  green: colors.green,
  grey: colors.grey,

  grey10: colors.grey10,
  grey20: colors.grey20,
  grey30: colors.grey30,
  grey40: colors.grey40,
  grey50: colors.grey50,
  grey60: colors.grey60,

  disabledBackground: colors.disabledBackground,
  disabledColor: colors.disabledColor,

  border: standard.border,
  borderRadius: standard.borderRadius,

  alertColors: colors.alert,
  infoColors: colors.info,
  successColors: colors.success,
  warningColors: colors.warning,

  //contentHorzPadding: 20,
  contentHorzPadding: 40,
  //contentVertPadding: 15,
  contentVertPadding: 5,
  componentPadding: standard.componentPadding,
  headerHeight: 48,
  //sidenavWidth: 116,
  sidenavWidth: 160,
  labelMarginLeft: 3,
  inputBorder: standard.border,
  inputMaxHeight: 24,
  inputHeight: 18,
  inputPadding: 6,
  inputTransition:
'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',

  labelHeight: standard.labelHeight,
  labelMargin: standard.labelMargin,
  labelFullHeight: standard.labelHeight + standard.labelMargin,

  wrapperHeight: 50,

  letterSpacing: 1,

  breakpointMobile: 480,
  breakpointTablet: 768,
};

export default theme;
