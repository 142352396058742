import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  quins: null,
};

const elasticReducer = createReducer(initialState, {
  SET_ELASTIC_RESULT: (state, action) => {
    state.quins = action.quins;
    console.log('set elastic result', {action, state});
  },
  CLEAR_ELASTIC_RESULT: (state) => {
    state.quins = null;
  },
});

export default elasticReducer;
