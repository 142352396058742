import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Icon from './Icon';
import styled from 'styled-components';
import { Row } from '../common';
import { setNotification } from '../../store/actions/notification';

export const failureNotificationMessage = 'Operation did not complete successfully';

const Spinner = ({
  className,
  loading = false,
  activeTheme
}) => {
  const dispatch = useDispatch();
  const [isTimedOut, setTimedOut] = useState(false);
  const [timeoutId, setTimeoutId] = useState(false);

  if(!loading && isTimedOut) {
    setTimedOut(false);
  }

  if(!loading && timeoutId) {
    clearTimeout(timeoutId);
    setTimeoutId(null);
  }

  const timeout = async () => {
    setTimedOut(true);
    dispatch(setNotification(failureNotificationMessage));
  }

  if(loading) {
    if(!timeoutId) {
      setTimeoutId(setTimeout(timeout, 25000));
    }
  }
  // loading ? 1 : 0 avoids an error in the console
  return (
    <>
      {!isTimedOut && loading ?
        <Wrapper className={className}>
          <Div activeTheme={activeTheme}>
            <Loading
              color={activeTheme?.spinnerIcon}
              name="loader"
              font="Feather"
              size={44}
              spin
            />
            <Row><span>Working...</span></Row>
          </Div>
        </Wrapper>
        :
        null
      }
    </>
  );
};

Spinner.displayName = 'Spinner';

Spinner.defaultProps = {
  className: '',
  loading: false,
};

Spinner.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default React.memo(Spinner);

const Wrapper = styled.div`
position: fixed;
z-index: 99999;
width: 100%;
height: 100%;
margin: -8px;
top: 0;
bottom: 0;
left: 0;
right: 0;
`;

const Div = styled.div`
background-color: ${({ activeTheme }) => activeTheme.spinnerBackground};
width: 160px;
height: 160px;
border-radius: 20px;
margin: auto;
position: absolute;
top: 0; left: 0; bottom: 0; right: 0;
flex-direction: column;
justify-content: center;
align-items: center;

span {
text-align: center;
font-weight: 700;
margin: 20px auto auto auto;
color: ${({ activeTheme }) => activeTheme.spinnerText};
}
`;

const Loading = styled(Icon)`
align-self: center;
margin: 0 auto;
`;
