import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

const Outer = Inner => {
  const FieldWrapper = ({ fields = [], input, meta, ...rest }) => {
    if (fields.length === 0) {
      const { value: passedValue, ...inputRest } = input;
      const value = passedValue !== '' ? passedValue : undefined;

      const {
        ...metaRest
      } = meta;

      return <Inner value={value} {...inputRest} {...metaRest} {...rest} />;
    } else {
      const {
        ...metaRest
      } = get(fields, '[0].meta', {});
      // eslint-disable-next-line no-unused-vars
      const { value: passedValue, ...inputRest } = get(fields, '[0].input', {});

      const values = [];

      fields.forEach(({ input }) => {
        // eslint-disable-next-line no-unused-vars
        const { value: passedValue, ...inputRest } = input;
        const value = passedValue !== '' ? passedValue : undefined;

        values.push(value);
      });

      return <Inner value={values} {...inputRest} {...metaRest} {...rest} />;
    }
  };

  FieldWrapper.displayName = 'FieldWrapper';

  FieldWrapper.propTypes = {
    fields: PropTypes.arrayOf(PropTypes.any),
    meta: PropTypes.object,
    input: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.bool,
        PropTypes.number,
        PropTypes.string,
      ]),
      onBlur: PropTypes.func,
      onChange: PropTypes.func,
      onFocus: PropTypes.func,
    }),
  };

  return FieldWrapper;
};

export default Outer;
