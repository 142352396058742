import React from 'react';
import PropTypes from 'prop-types';

import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { getModel } from 'qcp-js-ui-core/models';
import { buildView } from 'qcp-js-ui-core/component-logic/buildView';
import Modal from '../high-order/Modal';

import navigation from '../../implementation/navigation';

const SchemaView = ({ closeModal }) => {
  const dispatch = useDispatch();
  const {
    id,
    title,
    viewName,
    setShowLoading,
  } = useSelector(state => state.modal?.contents);
  const viewSchema = useSelector((state) => state.rms.schemas?.views?.[viewName]);
  const model = useSelector((state) => getModel(id, state.rms), shallowEqual);
  const viewJson = useSelector(state => state.rms.schemas.views);

  const visible = true;
  const getDeviceType = () => '';

  const content = buildView({
    model,
    isInProgress: false,
    json: viewSchema,
    navigation,
    setShowLoading,
    id,
    dispatch,
    viewJson,
    contextType: viewSchema.contextType,
    getDeviceType,
    fallbackContextAction: viewSchema.fallbackContextAction,
  });

  return <Modal title={title} visible={visible} onClose={closeModal}>
    {content}
  </Modal>;
};

SchemaView.displayName = 'SchemaView';

SchemaView.propTypes = {
  closeModal: PropTypes.func,
};

export default SchemaView;
