import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  outstandingRequests: [],
};

const requestsReducer = createReducer(initialState, {
  SET_OUTSTANDING_REQUESTS: (state, action) => {
    state.outstandingRequests = action.outstandingRequests;
    return state;
  }
});

export default requestsReducer;
