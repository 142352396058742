import React, { useEffect, useState, useContext } from 'react';
import { handleActions } from 'qcp-js-ui-core/actions';
import { getEndPoint } from 'qcp-js-ui-core/actions/endPointActions';
import { updateProperty } from 'qcp-js-ui-core/store/actions/rms';
import { Col, Label } from '../common';
import { useDispatch, useSelector } from 'react-redux';
import { TARGET_CONTEXT } from 'qcp-js-ui-core/constants';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Button from '../shared/Button';
import theme from '../theme';

const TextSearch = props => {
  const { id, fallbackContextAction, binding, autoSearch, searchAction, enabled, label } = props;
  const [searchText, setSearchText] = useState('');
  const [lastText, setLastText] = useState('');
  const [activeSearch, setActiveSearch] = useState(0);
  const dispatch = useDispatch();
  
  const themeContext = useContext(ThemeContext);
  const endPointAction = searchAction.actionType === 'actionGroupAction' ?
    searchAction.actions.find(action => action.endPoint) :
    searchAction;

  const endPointUri = useSelector((state) => getEndPoint(
    id,
    endPointAction,
    { text: searchText, [TARGET_CONTEXT]: binding },
    dispatch,
    () => state,
  ));

  const doSearch = () => {
    setActiveSearch(1);
    if(lastText.length > 0 && searchText === '' && fallbackContextAction) {
      dispatch(handleActions({ json: fallbackContextAction, quin: id }));
    } else if (searchText === '' && fallbackContextAction && activeSearch) {
      dispatch(handleActions({ json: fallbackContextAction, quin: id }));
    } else if (searchText === '' && !fallbackContextAction && activeSearch) {
      dispatch(updateProperty(binding.id, binding.property, []));
    } else if (searchText) {
      const options = {};
      const timeOutId = setTimeout(
        () => elasticSearch(searchText, options),
        1000,
      );
      setLastText(searchText);
      setActiveSearch(0);
      return () =>
        (options.cancelRequest && options.cancelRequest()) || clearTimeout(timeOutId);
    }
    setLastText(searchText);
    setActiveSearch(0);
  };

  useEffect(() => {
    if(!autoSearch) { return; }
    return doSearch();
  }, [endPointUri]);
  useEffect(() => { doSearch(); }, []);

  const elasticSearch = async (text, options) => {
    await dispatch(
      handleActions({
        json: searchAction,
        quin: binding.id,
        params: { text, [TARGET_CONTEXT]: binding },
        options,
      }),
    );
  };

  const handleTextChange = event => {
    setSearchText(event.currentTarget.value);
  };

  return (
    <Col>
      <Wrapper activeTheme={themeContext}>
        {label && <StyledLabel activeTheme={themeContext}>{label}</StyledLabel>}
        <input
          onChange={handleTextChange}
          disabled={!enabled}
          value={searchText}
          required={false}
          type="text"
          placeholder={'Search...'}
        />
      </Wrapper>
      {!autoSearch &&
<SearchButton>
  <Button
    label={'Search'}
    onPress={doSearch}
    enabled={true}
    icon={null}
    loading={false}
    type={'primary'}
    hAlign={true}
  />
</SearchButton>
      }
    </Col>
  );
};

TextSearch.propTypes = {
  fallbackContextAction: PropTypes.object,  
  binding: PropTypes.object,
  autoSearch: PropTypes.bool,
  searchAction: PropTypes.object,
  enabled: PropTypes.bool,
  id: PropTypes.string,  
};

export default TextSearch;

const SearchButton = styled.span`
position: relative;
top: 12px;
`;

const Wrapper = styled.div`
flex: ${({ flex }) => flex};
flex-direction: column;
min-height: ${({ showLabel }) =>
    theme.wrapperHeight - Number(!showLabel) * theme.labelFullHeight}px;
padding: ${theme.componentPadding};
width: 100%;
align-self: center;
> input {
height: ${theme.inputHeight}px;
min-height: ${theme.inputMaxHeight};
}
> input,
> textarea {
border: ${theme.inputBorder};
border-radius: ${theme.borderRadius}px;
display: flex;
font: inherit;
font-size: 14px;
outline: 0;
/* margin-top: 3px; */
padding: ${theme.inputPadding}px;
transition: ${theme.inputTransition};
background: ${({ activeTheme }) => activeTheme.inputBackground};
color: ${({ activeTheme }) => activeTheme.inputText};
filter: ${({ activeTheme }) => activeTheme.filter};
&:disabled {
background: ${theme.disabledBackground};
color: ${theme.disabledColor};
cursor: not-allowed;
}
&:focus {
border-color: ${theme.primary};
}
&::placeholder {
color: ${theme.grey};
}
`;

const StyledLabel = styled(Label)`
color: ${({ activeTheme }) => activeTheme.inputText};
filter: ${({ activeTheme }) => activeTheme.filter};
margin-left: ${theme.labelMarginLeft}px;
`
