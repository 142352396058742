import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { css, ThemeContext } from 'styled-components';
import { getBindingValue, getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { ARRAY } from 'qcp-js-ui-core/constants';
import { generateTempQuin } from 'qcp-js-ui-core/utils/uuid';
import { mergeModel } from 'qcp-js-ui-core/store/actions/rms';
import ViewComponent from '../ViewComponent';

import theme from '../theme';

const TypeSwitch = ({
  id,
  typeOptionsBinding,
  childContext,
  selectedTypeBinding,
  rowDivisions,
}) => {

  const selectedType = useSelector(state => {
    if (selectedTypeBinding) {
      return getValueFromBindingObject(selectedTypeBinding, state);
    }
  });
  const typeOptions = useSelector(state => {
    if (typeOptionsBinding) {
      return getValueFromBindingObject(typeOptionsBinding, state);
    }
  });

  const dispatch = useDispatch();
  const [childContextId, setChildContextId] = useState(generateTempQuin());

  const [key, setKey] = useState(Math.random());
  const childContextValue = useSelector(() => {
    return getBindingValue(childContext, id);
  });
  const themeContext = useContext(ThemeContext);

  useEffect(() => {
    dispatch(
      mergeModel(childContextId, {
        quin: childContextId,
        [ARRAY]: [],
      }),
    );
    setKey(Math.random());
  }, [selectedType]);

  useEffect(() => {
    if (childContextValue) {
      if (Array.isArray(childContextValue)) {
        const model = {
          quin: childContextId,
          [ARRAY]: childContextValue,
        };
        dispatch(mergeModel(childContextId, model));
      } else {
        setChildContextId(childContextValue);
      }
    }
  }, [childContextValue]);


  const getViewName = typeName => {
    const viewName = typeOptions?.filter(
      element => element.value === typeName,
    )?.[0];
    return viewName?.view;
  };

  return (
    <Div key={key} height={'auto'} rowDivisions={rowDivisions} className={'modalWrapper'} activeTheme={themeContext}>
      { selectedType &&
        <ViewComponent route={({
          key: getViewName(selectedType),
          quin: childContextId,
        })} />
      }
    </Div>
  );
};

export default TypeSwitch;

const Div = styled.div`
width: ${({ rowDivisions }) => rowDivisions ? `${98/rowDivisions}%` : '98%'};
flex-direction: column;
height: ${({ height }) => height};
font-size: .75rem;
font-family: ${theme.baseFont};
align-self: flex-start;
&.modalWrapper {
    .main {
        background-color: ${({ activeTheme }) => activeTheme?.bodyBackground}; !important;
    }
}
${({ loading }) =>
    loading &&
css`
span {
opacity: 0;
}
`}
${({ rowDivisions }) =>
    rowDivisions > 1 &&
css`
overflow-x: auto;
`}
`;
