import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Row } from '../common';
import Icon from '../shared/Icon';
import styled, {css, ThemeContext} from 'styled-components';
import getHighlightSeverityColor from '../../utils/highlight-severity-color';
import theme from '../theme';

const Panel = ({content, flexDirection, highlightSeverity, rowDivisions, weight, collapsible, enabled, alignItems}) => {
  const [collapsed, setCollapsed] = useState(collapsible);
  const themeContext = useContext(ThemeContext);

  const toggleCollapse = () => { setCollapsed(!collapsed) }

  const panelRow = <PanelRow
    enabled={enabled}
    activeTheme={themeContext}
    collapsible={collapsible}
    flexDirection={flexDirection}
    rowDivisions={rowDivisions}
    highlightSeverity={highlightSeverity}
    weight={weight}
    alignItems={alignItems}>
      {content}
  </PanelRow>
  if(!collapsible) { return panelRow }

  return <CollapsableRow activeTheme={themeContext} rowDivisions={rowDivisions}>
    {collapsed ? 
      <Icon onClick={toggleCollapse} color={themeContext.tableFilterBorder} size={16} name="chevron-right" font="Feather"/> :
      <Icon onClick={toggleCollapse} color={themeContext.tableFilterBorder} size={16} name="chevron-down" font="Feather"/>
    }
    {!collapsed && panelRow}
  </CollapsableRow>;
};

const getFullBorder = ({activeTheme}) => {
  const lastIndex = theme.inputBorder.lastIndexOf(' ');
  return `${
      theme.inputBorder.substring(0, lastIndex)
    } ${
      activeTheme.tableFilterBorder
  }`
}

const CollapsableRow = styled.div`
border: ${getFullBorder};
border-radius: ${theme.borderRadius}px;
padding: 10px;
margin: 0 10px;
${({ flexDirection }) =>
    css`
        ${flexDirection === 'flow' ? 'flex-flow: row; align-items: flex-start;' :  `flex-direction:${flexDirection}`};
    `}
`;

const getBorder = ({highlightSeverity}) => {
  if(!highlightSeverity) { return undefined; }
  const lastIndex = theme.inputBorder.lastIndexOf(' ');
  return `${
    theme.inputBorder.substring(0, lastIndex) 
  } ${
    getHighlightSeverityColor({highlightSeverity})
  }`;
};

const PanelRow = styled(Row)`
border: ${getBorder};
border-radius: ${theme.borderRadius}px;
${({ enabled }) =>
    !enabled &&
css`
pointer-events: none;
`}
${({ alignItems }) =>
    alignItems &&
    css`
        align-items: ${alignItems};
    `
}
${({ weight }) =>
    weight &&
    css`
        flex: ${weight};
    `
}
${({ rowDivisions }) =>
    rowDivisions != 1 &&
css`
width: ${100/rowDivisions}%;
`}
${({ flexDirection }) =>
    css`
        ${flexDirection === 'flow' ? 'flex-flow: row; align-items: flex-start;' :  `flex-direction:${flexDirection}`};
    `}
`;

Panel.displayName = 'Panel';

Panel.defaultProps = {
  children: null,
  rowDivisions: 1,
  flexDirection: 'column',
  weight: null,
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  rowDivisions: PropTypes.number,
  content: PropTypes.oneOfType([ PropTypes.element, PropTypes.array]),
};

export default Panel;