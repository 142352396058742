import React, { useRef, useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import theme from '../theme';
import styled from 'styled-components';
import Icon from './Icon';

const IconDropdown = ({
  name,
  color,
  size,
  options = [],
  newOptions,
  onView = () => {},
}) => {
  const menuRef = useRef();
  const iconRef = useRef();
  const [open, setOpen] = useState(false);
  const [down, setDown] = useState(true);
  const [left, setLeft] = useState(true);
  const [height, setHeight] = useState(0);

  const handleClick = () => {
    if (options.length) setOpen(!open);
    if (newOptions > 0) {
      onView();
    }
  };

  const handleClose = ev => {
    // async error requires !open (?)
    if (
      !open &&
menuRef.current &&
!menuRef.current.contains(ev.target) &&
iconRef.current &&
!iconRef.current.contains(ev.target)
    ) {
      setOpen(false);
    }
  };

  const handleViewResize = () => {
    if (iconRef.current) {
      const domRect = iconRef.current.getBoundingClientRect();
      setDown(
        document.documentElement.clientHeight - domRect.bottom > domRect.top,
      );
      setLeft(
        domRect.left > document.documentElement.clientWidth - domRect.right,
      );
      setHeight(domRect.bottom - domRect.top);
    }
  };

  const handleNavigation = path => {
    // May need to add absolute vs. component route handling
    if (path) {
      window.location = path;
    }
  };

  useEffect(() => {
    handleViewResize();
    document.addEventListener('mousedown', handleClose);
    window.addEventListener('resize', handleViewResize);

    return () => {
      document.removeEventListener('mousedown', handleClose);
      window.removeEventListener('resize', handleViewResize);
    };
  }, []);

  return (
    <Wrapper>
      {name && (
        <div ref={iconRef} style={{ cursor: 'pointer' }} onClick={handleClick}>
          <Icon name={name} font="Feather" color={color} size={size} />
        </div>
      )}
      {newOptions > 0 && <Badge>{options.length}</Badge>}
      {open && (
        <Menu down={down} left={left} height={height} ref={menuRef}>
          {options.map(({ message, optionIcon, path, overrideClick }) => (
            <MenuItem clickable={!!path} key={path} onClick={() => overrideClick ? overrideClick() : handleNavigation(path)}>
              <StyledDiv>
                <Icon name={optionIcon} font="Feather" size={18} color="black" />
                {message}
              </StyledDiv>
            </MenuItem>
          ))}
        </Menu>
      )}
    </Wrapper>
  );
};

IconDropdown.defaultProps = {
  icon: 'HelpCircle',
  color: theme.black,
  size: 36,
  options: [],
  newOptions: 0,
  onView: () => {},
};

IconDropdown.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.object),
  newOptions: PropTypes.number,
  onView: PropTypes.func,
};

const areEqual = (prevProps, nextProps) => {
  const {
    icon,
    color,
    size,
    options,
    newOptions,
    onView = () => {},
  } = prevProps;
  const {
    icon: nextIcon,
    color: nextColor,
    size: nextSize,
    options: nextOptions,
    newOptions: nextNewOptions,
    onView: nextOnView = () => {},
  } = nextProps;

  if (
    icon !== nextIcon ||
color !== nextColor ||
size !== nextSize ||
options.length !== nextOptions.length ||
newOptions !== nextNewOptions ||
onView.toString() !== nextOnView.toString()
  ) {
    return false;
  }

  for (let i = 0; i < options.length; i++) {
    if (
      options[i].message !== nextOptions[i].message ||
options[i].optionsIcon !== nextOptions[i].optionsIcon ||
options[i].path !== nextOptions[i].path
    ) {
      return false;
    }
  }

  return true;
};

export default memo(IconDropdown, areEqual);
export { areEqual };

const Menu = styled.div`
background: white;
border-radius: 4px;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
display: flex;
flex: 1;
flex-direction: column;
margin: 0 14px;
padding: 7px 0;
position: absolute;
width: 200px;
/* ${({ left }) => (left ? 'right: -10px' : 'left: -15px')}; */
right: -15px;
${({ down, height }) =>
    down ? `top: ${height + 20}px` : `bottom: ${height + 20}px`};
border: solid transparent;
-webkit-filter: drop-shadow(0 0 0.15rem rgba(104, 104, 104, 0.5));
-webkit-transform: translateZ(0);

&:after {
content: ' ';
border-right: 1em solid transparent;
${({ down }) =>
    down
      ? 'border-bottom: 1.2em solid white'
      : 'border-top: 1.2em solid white'};
border-left: 1em solid transparent;
position: absolute;
/* ${({ left }) => (left ? 'right: 0px' : 'left: 0px')}; */
right: 0px;
${({ down }) => (down ? 'bottom: 100%' : 'top: 100%')};
}

&:before {
content: ' ';
border-right: 1em solid transparent;
border-left: 1em solid transparent;
${({ down }) =>
    down
      ? 'border-bottom: 1.2em solid rgba(168, 168, 168, 0.5)'
      : 'border-top: 1.2em solid rgba(168, 168, 168, 0.5)'};
position: absolute;
/* ${({ left }) => (left ? 'right: 0px' : 'left: 0px')}; */
right: 0px;
${({ down }) => (down ? 'bottom: 100%' : 'top: 100%')};
}
`;

const MenuItem = styled.div`
align-items: center;
display: flex;
flex: 1;
flex-direction: row;
font-size: 14px;
height: 28px;
justify-content: flex-start;
padding: 4px 14px;
cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};

&:hover {
background: ${({ clickable }) => (clickable ? '#0099ff50' : 'white')};
}
`;

const Wrapper = styled.div`
position: relative;

> svg {
color: #555;
cursor: pointer;
}
`;

const Badge = styled.div`
background-color: #55dc55;
position: absolute;
right: -3px;
top: -3px;
border-radius: 50%;
width: 15px;
height: 15px;
color: white;
justify-content: center;
font-size: 11px;
line-height: 15px;
`;

const StyledDiv = styled.div`
height: 36px;
align-items: center;

i {
    margin-right: 10px;
}
`;
