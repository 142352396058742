import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import styled, { css, ThemeContext } from 'styled-components';
import { Col } from '../common';

import theme from '../theme';

const Button = ({
  passed,
  className,
  enabled = true,
  dropdown,
  icon,
  iconType,
  id,
  loading,
  label,
  type,
  onPress = () => {},
  hAlign,
}) => {
  const handleClick = ev => {
    ev.preventDefault();
    return enabled && !loading && onPress(ev);
  };
  const themeContext = useContext(ThemeContext);
  const content =
<Wrapper
  id={id || label}
  className={className}
  disabled={!enabled}
  dropdown={dropdown}
  type={type}
  onClick={handleClick}
  loading={loading ? 1 : 0}
  title={label}
  icon={icon}
  passed={passed}
  activeTheme={themeContext}
  //onMouseOver={() => setIsHover(true)}
>
  {!!icon && (
    <Icon
      color={type == 'primary' ? theme.white : theme.orange}
      name={icon}
      font={iconType}
      size={16}
      //onMouseOver={() => setIsHover(true)}
    />
  )}
  {!!loading && (
    <Loading
      color={type == 'primary' ? theme.white : theme.orange}
      icon="Loader"
      spin
    />
  )}
  <Div icon={icon} loading={loading ? 1 : 0} title={label}>{(!!label && !icon) && <Label activeTheme={themeContext}>{label}</Label>}</Div>
</Wrapper>;

  const display = className === '' ? <StyledCol icon={icon} hAlign={hAlign}>{content}</StyledCol> : content;
  // loading ? 1 : 0 avoids an error in the console
  return (
    display
  );
};

Button.displayName = 'Button';

Button.defaultProps = {
  passed: null,
  className: '',
  enabled: true,
  icon: null,
  iconType: null,
  id: null,
  loading: false,
  title: null,
  type: 'default',
  onPress: () => {},
  hAlign: 'normal',
};

Button.propTypes = {
  passed: PropTypes.number,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  icon: PropTypes.string,
  iconType: PropTypes.string,
  dropdown: PropTypes.bool,
  id: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string,
  type: PropTypes.string,
  onPress: PropTypes.func,
  hAlign: PropTypes.string,
};

export default Button;

const Label = styled.span`
${({ activeTheme }) => css`filter: ${activeTheme.filterButton};`}
`

const Div = styled.div`
flex-direction: column;
font-size: .65rem;
font-family: ${theme.baseFont};
text-transform: initial;
${({ loading }) =>
    loading &&
css`
span {
opacity: 0;
}
`}
`;

const Loading = styled(Icon)`
position: absolute;
`;

const Wrapper = styled.button`
align-items: center;
align-self: center;
background: ${({ type, icon }) => icon ? theme.gradientBlueDark : theme[type]};
border: 2px solid ${({ icon }) =>
    icon == null ? theme.greywhite : theme.orange};
border-radius: ${theme.borderRadius}px;
box-shadow: rgba(30, 22, 54, 0.1) 0 0px 0px 0px inset;
color: ${({ icon }) => (icon == null ? theme.black : theme.orange)};
cursor: pointer;
display: flex;
height: ${({ icon }) => icon == null ? '30px' : '25px'};
justify-content: center;
letter-spacing: ${theme.letterSpacing}px;
/* padding: 0 16px; */
position: ${({ className }) => (!className.includes('-button') ? 'relative' : 'absolute')};
/*top: -6px;*/
text-decoration: none;
text-transform: uppercase;
transition: all 200ms cubic-bezier(0.39, 0.5, 0.15, 1.36);
margin-right: 5px;
transition: all 0.2s linear 0s;
${({ activeTheme }) => css`filter: ${activeTheme.filter};`}
${({ icon, title }) =>
    icon &&
css`
&:before {
    content: "${title}";
    font-size: .65rem;
    text-transform: initial;
    font-family: ${theme.baseFont};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: -50px;
    width: 0px;
    height: 100%;
    transition: all 0.2s linear 0s;
    text-align: center;
    display: none;
  }
    &:hover {
        justify-content: flex-start;
        width: 100px;
        background: ${theme.newOrange} !important;
        i {
            display: none;
        }
        &:before {
            right: 0;
            left: 0;
            top: 25%;
            text-indent: 0px;
            width: 100%;
            display: inline-block;
        }
        span {
            display: block;
            text-indent: -30px;
        }
    }
`}
&:disabled {
color: ${theme.white} !important;
background: ${({ activeTheme }) => activeTheme.disabledButton};
cursor: not-allowed;
font-style: italic;
}

&:hover {
color: ${theme.orange};
border-color: ${theme.orange};
${({ icon }) =>
    icon &&
`background: rgb(${theme.orangeRgb});
background: linear-gradient(180deg, rgba(${theme.orangeRgb},0.10) 100%, rgba(${theme.orangeRgb},0.10) 100%);`}

span {
display: block;
}
}
&:active, &:focus, &:visited {
background: ${theme.whitegrey};
border: 2px solid ${theme.orange};
}
${({ dropdown, title }) => {
    return (
      !dropdown &&
title &&
css`
> svg {
margin-right: 0px;
}
`
    );
  }}
${({ dropdown }) => {
    return (
      dropdown &&
css`
> svg {
margin-left: 10px;
}
`
    );
  }}
${({ type, icon, disabled }) =>
    type === 'primary' &&
css`
color: white;
border: none;
background: ${icon ? theme.gradientBlueDark : theme.gradientBlueLight};
${!disabled &&
    css`
&:hover {
color: ${theme.white};
border-color: ${theme.orange};
background: ${theme.gradientBlueDark};
}`}
&:active,
&:focus,
&:visited {
background: ${theme.orangeDark};
border: 2px solid ${theme.orange};
}
`}

${({ className }) =>
  {
    const headerCoefficient = 2.22;
    return className === 'image-button' ?
      css`
top: ${theme.headerHeight * headerCoefficient};
left: 155;
`
      :
      css``;
  }
}

`;

const StyledCol = styled(Col)`
${({ hAlign}) => hAlign == 'normal' && 'flex: none'};
justify-content: ${({ hAlign}) => hAlign == 'normal' ? 'normal' : 'flex-end'};
${({ icon }) =>
    icon &&
    css`
        flex-grow: inherit;
    `};
`;
