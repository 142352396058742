import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const Divider = ({}) => {
    let visible = true;
  const themeContext = useContext(ThemeContext);
  return (
        <HR color={themeContext.divider} visible={visible} />
  );
};

export default Divider;

const HR = styled.hr`
width: 100%;
border-top: 1px solid ${({ color }) => color};
border-bottom: 0;
margin: 10px 0 10px 0;
visibility: ${({ visible }) => visible ? 'visible' : 'hidden' }
`;
