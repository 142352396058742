import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { useSelector } from 'react-redux';
import theme from '../theme';
import Spinner from './Spinner';
import Icon from './Icon';

const retryStatuses = [ 403, 404, 503 ];

const BoundImage = ({ binding, fileType }) => {
  const url = useSelector((state) => getValueFromBindingObject(binding, state));
  const [ loadedStatus, setLoadedStatus ] = useState('loading');
  const [ loadedUrl, setLoadedUrl ] = useState('');

  useEffect(() => {
    (async () => {
      const timeout = Date.now() + 120000;
      while(Date.now() < timeout) {
        await sleep(500);
        const response = await fetch(url);
        if(!retryStatuses.includes(response.status)) {
          if(response.status === 200) {
            setLoadedUrl(url);
            setLoadedStatus('loaded');
            return;
          }
        }
      }
      setLoadedStatus('errored');
    })();
  }, []);

  if(loadedStatus === 'loading') {
    return <Icon
      color={theme.orange}
      icon="Loader"
      spin
    />;
  } else if (loadedStatus === 'errored') {
    return <p>Error waiting on download URL</p>;
  }

  if(fileType === 'pdf') {
    location.href = url;
    return <Spinner className='loading' loading={true} />;
  }

  return <PhotoContainer> <Link href={loadedUrl} target="_blank"><Photo src={loadedUrl} /></Link> </PhotoContainer>;
};

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const Link = styled.a`
`;
const Photo = styled.img`
border: 1px solid ${theme.grey30};
border-radius: 2px;
padding: 5px;
height: auto;
max-height: 100px;

&:hover {
opacity: .5
}
`;

const PhotoContainer = styled.div`
flex: 0 1 auto;
margin-bottom: 20px;
`;

BoundImage.protoTypes = {
  binding: PropTypes.object,
};

export default BoundImage;
