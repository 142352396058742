import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { getValueFromBindingObject } from 'qcp-js-ui-core/component-logic/binding';
import { getModel } from 'qcp-js-ui-core/models';
import { resolveSourceFunction } from 'qcp-js-ui-core/utils/source-function';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import theme from '../theme';

const formatDate = (date) =>
  `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

const getPrintableValue = (value) => {
  switch (value?.constructor?.name) {
  case 'Date':
    return formatDate(value);
  case 'Boolean':
    return value ? 'Yes' : 'No';
  default:
    return value;
  }
};

const getStaticOptions = (qType, property) => (state) => {
  const options = state?.rms?.schemas?.models?.[qType]?.[`${property}_options`];
  return options?.map(option => ({
    value: option.value,
    label: option.display,
  }));
};

export const HighlighterText = ({
  label,
  value,
  highlighted = false,
  onClick = () => {},
}) => {
  const themeContext = useContext(ThemeContext);

  if(typeof value !== 'string') {
    console.error(`Error: ${label} value is not a string`);
    return null;
  } else {
    return (
      <Wrapper activeTheme={themeContext} highlighted={highlighted} onClick={onClick}>
        {label &&
            <span style={{fontSize: 16}}>{label}</span>
        }
        <p>{value}</p>
      </Wrapper>
    );
  }

};

const Highlighter = ({
  label,
  json,
  binding,
  highlighted = false,
  onClick = () => {},
}) => {
  const [curVal, setVal] = useState('');
  const dispatch = useDispatch();
  const { id, property } = binding;
  const isTemp = (id?.startsWith('temp') || id?.startsWith(':')) ? true : false;
  const value = useSelector(state => getValueFromBindingObject(binding, state), shallowEqual);
  const model = useSelector(state => id && getModel(id, state.rms));
  const qType = isTemp ? model._modelType : model?.qType;
  const staticOptions = useSelector(getStaticOptions(qType, property));
  const themeContext = useContext(ThemeContext);

  const parseDisplay = quin => async (dispatch, getState) => {
    let displayValue = await resolveSourceFunction(
      json.optionsDisplayFormat,
      quin,
      dispatch,
      getState,
    );
    if (displayValue === null) displayValue = '';
    return displayValue;
  };

  const getObjectValue = value => {
    return dispatch(parseDisplay(value.destQuin));
  };

  const getObjectValueBuffer = async item => {
    return getObjectValue(item);
  };

  const getAllObjectValues = async (values) => {
    return Promise.all(values.map(item => getObjectValueBuffer(item)));
  };

  const getValue = (value) => {
    switch(typeof value) {
    case 'string':
      if(json.valueType === 'date') {
        const correctedValue = value.split && value.split('#')[0];
        return setVal(moment(correctedValue).format('MMMM Do YYYY'));
      } else {
        if(qType && staticOptions) {
          const selectedOption = staticOptions.find(option =>
            value === option?.value);
          return setVal(selectedOption.label);
        } else {
          return setVal(value);
        }
      }
    case 'boolean':
      return setVal(value ? 'Yes' : 'No');
    case 'number':
      return setVal(String(value));
    case 'object':
      if(value?.destQuin) {
        getObjectValue(value)
          .then(result => {
            let res = result;
            return setVal(res);
          });
      } else {
        if(Array.isArray(value)) {
          getAllObjectValues(value)
            .then(result => {
              let res = result;
              return setVal(res.map(getPrintableValue).join(', '));
            });
        } else {
          return setVal(getPrintableValue(value));
        }
      }
      return null;
    default:
      return null;
    }
  };

  useEffect(() => {
    getValue(value);
  }, []);

  return (
    <Wrapper activeTheme={themeContext} highlighted={highlighted} onClick={onClick}>
      {label &&
        <StyledSpan activeTheme={themeContext} isEmpty={curVal == '' ? true : false}>{label}</StyledSpan>
      }
      { curVal != '' &&
      <p>{curVal}</p>
      }
    </Wrapper>
  );
};

Highlighter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
  ]),
  highlighted: PropTypes.bool,
  json: PropTypes.object,
  onClick: PropTypes.func,
  binding: PropTypes.object,
};

export default Highlighter;

const Wrapper = styled.div`
flex: 1;
flex-direction: column;
padding: ${theme.componentPadding};
width: 100%;
background-color: ${({ highlighted }) =>
    highlighted ? '#9ccfde5c' : 'transparent'};
border-radius: 5px;
cursor: pointer;
min-height: 40px;
> p {
margin: 0;
padding: 2px;
border-bottom: 1px dotted ${({ activeTheme }) => activeTheme.highlighterBorder};
color: ${({ activeTheme }) => activeTheme.highlighterValue};
filter: ${({ activeTheme }) => activeTheme.filter};
width: max-content;
}
`;

const StyledSpan = styled.span`
    font-size: 12px;
    letter-spacing: 1px;
    color: ${({ activeTheme, isEmpty }) => isEmpty ? activeTheme.highlighterLabelEmpty : activeTheme.highlighterLabel};
    filter: ${({ activeTheme }) => activeTheme.filter};
    text-transform: uppercase;
    font-weight: ${({ isEmpty }) => isEmpty ? 300 : 300};
`;
