import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css, ThemeContext } from 'styled-components';
import Field from '../high-order/Field';
import Icon from '../shared/Icon';

import { Label, Col } from '../common';
import Tooltip from '../shared/Tooltip';
import getHighlightSeverityColor from '../../utils/highlight-severity-color';
import theme from '../theme';

import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const Input = ({binding, generateEngine}) => {
    const themeContext = useContext(ThemeContext);
    const [displayed, setDisplayed] = useState(false);
    const tooltipRef = useRef();
  const [textBox, setTextBox] = useState({
    onChange: () => null,
    displayValue: '',
    rowCount: 1,
    isMultiLine: false,
    enabled: false,
    label: '',
    isNIBRS: false,
    tooltip: false,
    weight: 1,
    errorMessage: null,
  });

  useEffect(() => generateEngine(setTextBox), []);

  const {
    onChange,
    displayValue,
    isMultiLine,
    enabled,
    label,
    isNIBRS,
    tooltip,
    highlightSeverity,
    weight,
    markdown,
    errorMessage,
  } = textBox;
  
  const showLabel = true;
  const required = false;

  const handleTextChange = (event) => {
    let encodedText = Buffer.from(event.target.value, 'utf-8');
    encodedText = encodedText.toString();
    enabled && onChange(encodedText);
  };

  const mdParser = new MarkdownIt();

  const [editorValue, setEditorValue] = useState( () => displayValue && displayValue );

  const handleTextEditorChange = ({ html, text }) => {
    enabled && onChange(JSON.stringify(text));
    setEditorValue(text);
  }

  const showToolTip = () => {
    setDisplayed(true);
  };  

  const hideToolTip = () => {
    setDisplayed(false);
  }

  useEffect(() => {
    if(markdown) {
        validJson = (value) => {
            try {
                return JSON.parse(value);
            } catch (err) {
                return value;
            }
            
        }
      const parsed = validJson(displayValue);
      setEditorValue(parsed);
    }
  }, [displayValue])

  const changeModeText = (element) => {
    setTimeout(() => {
      const modeIcon = document.querySelector('.button.button-type-mode i');
      let classname = modeIcon.className;
      let searchableClasses = [
          {
            class: 'rmel-iconfont rmel-icon-keyboard',
            text: 'Editor & Preview View'
          },
          {
            class: 'rmel-iconfont rmel-icon-visibility',
            text: 'Editor View'
          },
          {
            class: 'rmel-iconfont rmel-icon-view-split',
            text: 'Preview View'
          },
        ]

      let text = searchableClasses.filter((c, i) => c.class == classname);
      element.innerText = `${text ? text[0].text : ''}`;
    }, 300);
  }

  useEffect(() => {
    if(markdown) {
      const modeButton = document.querySelector('.button.button-type-mode');
      const rightIcon = document.querySelector('.navigation-nav.right');
      const iconDiv = document.createElement('p');
      rightIcon.prepend(iconDiv);
      changeModeText(iconDiv);
      modeButton.addEventListener('click', () => changeModeText(iconDiv));
      const markdownWrapper = document.querySelector(`.markdown-wrapper-${binding?.id}-${binding?.property}`);
      const resizeObserver = new ResizeObserver(entries => {
        if((markdownWrapper.getBoundingClientRect().height > 200) && window.innerHeight - markdownWrapper.getBoundingClientRect().bottom < 10) {
          setTimeout(() => 1000, window.scrollTo(0, document.body.scrollHeight));
        }
      });
      
      if(markdownWrapper) {
        resizeObserver.observe(markdownWrapper);
      }
    }
  }, [label])

  useEffect((() => {
    document.body.addEventListener('click', hideToolTip)
  }), [])

  return <InputComponent
    enabled={enabled}
    isNIBRS={isNIBRS}
    label={showLabel ? label : null}
    displayValue={displayValue}
    required={required}
    handleTextChange={handleTextChange}
    error={errorMessage}
    isMultiLine={isMultiLine}
    tooltip={tooltip}
    showToolTip={showToolTip}
    hideToolTip={hideToolTip}
    displayTooltip={displayed}
    tooltipRef={tooltipRef}
    highlightSeverity={highlightSeverity}
    weight={weight}
    handleTextEditorChange={handleTextEditorChange}
    markdown={markdown}
    activeTheme={themeContext}
    mdParser={mdParser}
    setEditorValue={setEditorValue}
    editorValue={editorValue}
    eleId={`${binding?.id}-${binding?.property}`}
  />;
};

Input.propTypes = { generateEngine: PropTypes.func };

const ControlledInput = (props) => {
   const { value, onChange, createComponent, ...rest } = props;
   const [cursor, setCursor] = useState(null);
   const ref = useRef(null);

   useEffect(() => {
      const input = ref.current;
      if (input) input.setSelectionRange(cursor, cursor);
   }, [ref, cursor, value]);

   const handleChange = (e) => {
      setCursor(e.target.selectionStart);
      onChange && onChange(e);
   };
   return createComponent({ref, value, onChange: handleChange, ...rest});
};

const InputConstructor = (args) =>  <input {...args} />;

const TextAreaConstructor = (args) =>  <textarea {...args} />;

const InputComponent = (
  { enabled, isNIBRS, label, displayValue, required, handleTextChange, error, isMultiLine, tooltip, showToolTip, tooltipRef, displayTooltip, highlightSeverity, weight, markdown, handleTextEditorChange, mdParser, editorValue, eleId, activeTheme }
) => (
    <StyledCol weight={weight}>
        <Wrapper activeTheme={activeTheme} highlightSeverity={highlightSeverity} isMultiLine={isMultiLine}>
            <StyledLabel ref={tooltipRef} disabled={!enabled} isNIBRS={isNIBRS} activeTheme={activeTheme}>
                {label}
                {tooltip &&
                <StyledIcon display={'inline'} onPress={showToolTip} color={displayTooltip ? theme.red : activeTheme.iconColor} name={displayTooltip ? 'x' : 'help-circle'} font="Feather" size={14} />
                }
            </StyledLabel>
            {tooltip &&
                <Tooltip tooltipRef={tooltipRef} shown={displayTooltip} content={tooltip} direction="top" delay={400} />
            }
        {!isMultiLine && (
            enabled ?
            <ControlledInput
            createComponent={InputConstructor}
            disabled={!enabled}
            value={displayValue}
            required={required}
            type="text"
            onChange={handleTextChange}
            />
            :
            <Border activeTheme={activeTheme}>
                <span>{displayValue}</span>
            </Border>
        )}
        {isMultiLine && (
            markdown ?
            <StyledWrapper className={`markdown-wrapper-${eleId}`}>
                <StyledMdEditor view={{menu:enabled, md:enabled, html:true}} value={editorValue} renderHTML={text => mdParser.render(text)} onChange={handleTextEditorChange} />
            </StyledWrapper>
            :
            <ControlledInput
            createComponent={TextAreaConstructor}
            disabled={!enabled}
            required={required}
            rows={isMultiLine === 'large' ? 5 : 3}
            type="text"
            value={displayValue}
            onChange={handleTextChange}
            />
        )}
        {error && (
            <Label error style={{ textAlign: 'right' }}>
            {error}
            </Label>
        )}
        </Wrapper>
    </StyledCol>
);

Input.displayName = 'Input';

Input.defaultProps = {
  disabled: false,
  value: '',
  error: null,
  flex: 1,
  label: null,
  placeholder: '',
  required: true,
  rows: 1,
  touched: false,
  type: 'text',
  isNIBRS: false,
  tooltip: false,
  onChange: () => {},
  weight: 1,
};

Input.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  flex: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  pattern: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  touched: PropTypes.bool,
  type: PropTypes.oneOf(['email', 'number', 'password', 'text', 'tel']),
  isNIBRS: PropTypes.bool,
  onChange: PropTypes.func,
  weight: PropTypes.number,
};

export default Input;
export const FieldInput = Field(Input);
export { InputComponent };

const StyledCol = styled(Col)`
position: relative;
${({ weight }) =>
    weight &&
css`
flex: ${weight};
`}
`;

const StyledIcon = styled(Icon)`
margin-left: 5px;
`;

const Wrapper = styled.div`
flex: ${({ flex }) => flex};
flex-direction: column;
min-height: ${({ showLabel }) =>
    theme.wrapperHeight - Number(!showLabel) * theme.labelFullHeight}px;
padding: ${theme.componentPadding};
width: 100%;
${({ isMultiLine }) => isMultiLine &&
css`width: 100%`};
> input {
height: ${theme.inputHeight}px;
min-height: ${theme.inputMaxHeight}px;
}
> input,
> textarea {
border: ${theme.inputBorder};
border-radius: ${theme.borderRadius}px;
border-color: ${getHighlightSeverityColor};
display: flex;
font: inherit;
font-size: .75rem;
outline: 0;
padding: ${theme.inputPadding}px;
transition: ${theme.inputTransition};
background: ${({ activeTheme }) => activeTheme?.inputBackground};
color: ${({ activeTheme }) => activeTheme?.inputText};
filter: ${({ activeTheme }) => activeTheme?.filter};
&:disabled {
background: ${({ activeTheme }) => activeTheme?.inputBackground};
color: ${theme.disabledColor};
cursor: not-allowed;
}
&:focus {
border-color: ${theme.primary};
}
&::placeholder {
color: ${theme.grey};
}
${({ showError }) =>
    showError &&
css`
border: 1px solid ${theme.error};
`}
`;

const StyledLabel = styled(Label)`
color: ${({ isNIBRS, activeTheme }) => isNIBRS ? activeTheme?.labelNIBRS : activeTheme?.label};
filter: ${({ activeTheme }) => activeTheme?.filter};
margin-left: ${theme.labelMarginLeft}px;
display: flex;
`;

const StyledWrapper = styled.div`
resize: both;
overflow: scroll;
min-height: 150px;
.rc-md-editor {
    width: 100%;
}
.rc-md-editor .button-type-code-inline,
.rc-md-editor .button-type-code-block,
.rc-md-editor .button-type-image,
.rc-md-editor .button-type-fullscreen,
.rc-md-editor .button-type-underline {
    display: none !important;
}
.rc-md-editor .editor-container .sec-md .input {

}
`;

const StyledMdEditor = styled(MdEditor)`
.rc-md-navigation .right p {
    font-size: .75rem;
    text-transform: uppercase;
    border-bottom: 1px solid #cecece;
}
.sec-html {
    position: relative;
    z-index: 1;
}
.sec-html::after {
    z-index: 1;
    content: "Preview";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg,#f5f5f5,#f5f9fa);
    content: "PREVIEW";
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .25;
    font-size: 5rem;
    color: #c0c0c0;
    letting-spacing: .5rem;
}
.custom-html-style {
    display: block;
}
`;

const Border = styled.div`
background: ${({ activeTheme }) => activeTheme?.inputBackground};
border-radius: 4px;
border: 1px solid #e6e6e6;
padding: ${theme.inputPadding}px ${theme.inputPadding * 2}px;
line-height: 12px;
min-height: 12px;
cursor: not-allowed;
`;
