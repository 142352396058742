import React, { useEffect, useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { Wrapper, Tab } from './Filter';
import { LogiclessCheckbox } from '../form/Checkbox';
import { UnboundDateTimePicker } from '../form/DateTimePicker';
import Icon from '../shared/Icon';
import Button from '../shared/Button';
import theme from '../theme';
import RC_Table from 'rc-table';
import { buildView } from 'qcp-js-ui-core/component-logic/buildView';
import { getModel } from 'qcp-js-ui-core/models';
import navigation from '../../implementation/navigation';

const rowHeight = 35;

import moment from 'moment-timezone';

moment.tz.setDefault('America/Chicago');

const CheckboxWrapper = ({ startValue, onValueChange = (() => null) }) => {
  const [checked, setChecked] = useState(startValue);
  const onClick = () => {
    const newValue = !checked;
    setChecked(newValue);
    onValueChange(newValue);
  };
  return <span>
    <LogiclessCheckbox value={checked} onClick={onClick} />
  </span>;
};

CheckboxWrapper.propTypes = {
  startValue: PropTypes.bool,
  onValueChange: PropTypes.func,
};

const ValueHoldingCheckbox = ({label, value, setValue, index}) => {
  const [stateValue, setStateValue] = useState(value);
  const handleClick = () => {
    setValue(!stateValue);
    setStateValue(!stateValue);
  }
  return <LogiclessCheckbox
    label={label}
    stateValue={stateValue}
    handleClick={handleClick}
    key={index}
    enabled={true}
  />
}

const getFilterComponent = (theme) => ({label, value, component, select, deselect}, index) => {
  const setValue = (value) => {
    (value ? select : deselect)();
  }
  if(component === 'date') {
    return <UnboundDateTimePicker
      key={`${label}_${index}`}
      label={label}
      mode={'date'}
      enabled={true}
      setValue={setValue}
      start={value}
      future={true}
    />
  } else {
    return <ValueHoldingCheckbox
      label={label}
      stateValue={value}
      setValue={setValue}
      key={index}
    />
  }
}

const FilterGroupDropdown = ({themeContext, label, selections}) => {
  const [isDropped, setIsDropped] = useState(false);
  const getThemedFilterComponent = getFilterComponent(themeContext);
  return <FilterGroup activeTheme={themeContext} key={label}>
      {
        label && <>
            <FilterDropdownArrow
              color={theme.black}
              size={16}
              name={isDropped ? 'chevron-down' : 'chevron-right'}
              font='Feather'
              onClick={() => setIsDropped(!isDropped)}
            />
            <FilterLabel
              activeTheme={themeContext}
              onClick={() => setIsDropped(!isDropped)}
            >
                {label}
            </FilterLabel>
          </>
      }
      {
        isDropped && selections.map(getThemedFilterComponent)
      }
    </FilterGroup>
}

const Table = ({generateEngine}) => {
  const [table, setTable] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [isSortedOn, setIsSortedOn] = useState(null);
  const [displayedEntities, setDisplayedEntities] = useState(new Set());
  const [height, setHeight] = useState(window.innerHeight);
  const [selectedRow, setSelectedRow] = useState('');
  const [openAccordions, setOpenAccordions] = useState([])
  const viewJson = useSelector(state => state.rms.schemas.views);
  const dispatch = useDispatch();
  const updateWindowHeight = () => {
    setHeight(window.innerHeight);
  };
  const themeContext = useContext(ThemeContext);
  const filterContextView = useSelector((state) => {
    if(!table?.filterContext?.quin) { return }
    const model = getModel(table.filterContext.quin, state.rms);
    if(!model) { return }
    return buildView({
      id: model.quin,
      isInProgress: false,
      model: model,
      viewJson,
      navigation,
      setShowLoading: () => null,
      json: table.filterContext.schema,
      contextType: model.qType,
      getDeviceType: () => null,
      fallbackContextAction: null,
      dispatch,
    })
  });

  useEffect(() => {
    window.addEventListener('resize', updateWindowHeight);
    return () => window.removeEventListener('resize', updateWindowHeight);
  }, []);
  useEffect(() => generateEngine(setTable), []);
  
  const toggleDisplayedEntity = (entityId) => () => {
    const entities = new Set(displayedEntities.values());
    if(entities.has(entityId)) {
      entities.delete(entityId);
    } else {
      entities.add(entityId);
    }
    setDisplayedEntities(entities);
  };

  const toggleAccordion = (index) => {
    let updatedAccordions = [...openAccordions];
    if(!updatedAccordions.includes(index)) {
        updatedAccordions.push(index);
    } else {
        let getIndex = updatedAccordions.indexOf(index);
        if (getIndex > -1) {
          updatedAccordions.splice(getIndex, 1);
        }
    }
    setOpenAccordions(updatedAccordions);
  }

  const getKey = (index) => `key${index}`;

  useEffect(() => {
    if(!table.loadMore) { return; }
    const getLastRecord = () => document.querySelector(
      `[data-row-key="${getKey(`0-${table.rows.length - 1}`)}"]`,
    );
    const lastRecord = getLastRecord();
    let seen = 0;
    const observer = new IntersectionObserver(() => {
      seen = seen + 1;
      if(seen < 2) { return; }
      table.loadMore();
    });
    if(!lastRecord) { return; }
    observer.observe(lastRecord);
    return (() => { observer.unobserve(lastRecord); });
  }, [table.loadMore]);

  if(!table.rows) {return <div />;}
  const getCellContents = (value) => {
    const icon = value?.icon?.url ? value.icon.url : value.icon;
    const tooltip = value?.icon?.tooltip;
    if(!value.visible) return;
    if(icon) { return <Icon onClick={() => value.onPress()} color="#a2a2a2" name={icon} font="Feather" size={16} tooltip={tooltip} /> }
    else { return value?.text ?? ''; }
  };

  const handleColumnClick = (index, sortOnAscending, sortOnDescending) => () => {
    setIsSortedOn(index);
    if(table.isAscending) {
      sortOnDescending();
    } else {
      sortOnAscending();
    }
  };

  const getOnTabClick = (filter, index) => () => {
    table.clearFilters();
    setActiveTab(index);
    filter();
  };

  const getFilterTab = ({ label, filter }, index) => (
    <Tab
      key={index}
      active={activeTab === index}
      onClick={getOnTabClick(filter, index)}
      activeTheme={themeContext}
    >
      <span>{ label || 'unamed' }</span>
    </Tab>
  );

  getFilterTab.propTypes = {
    label: PropTypes.string,
    filter: PropTypes.func,
  };

  const getOnExclusiveTabClick = (filter, index) => () => {
    setActiveTab(index);
    filter();
  };

  const getExclusiveFilterTab = ({ label, select }, index) => (
    <Tab
      key={index}
      active={activeTab === index}
      //onClick={getOnExclusiveTabClick(select, index)}
      activeTheme={themeContext}
    >
      <span onClick={getOnExclusiveTabClick(select, index)}>{ label || 'unamed' }</span>
    </Tab>
  );

  getExclusiveFilterTab.propTypes = {
    label: PropTypes.string,
    select: PropTypes.func,
  };

  const handleGroupFilterClick = (value, select, deselect) => () => {
    if(value) {
      deselect();
    } else {
      select();
    }
  };

  const hasSideFilters = !!table?.filterGroups?.multiSelect?.length;

  const rcColumns = table.columns.map(({
    label,
    sortOnAscending,
    sortOnDescending,
  }, index) => ({
    title: label,
    dataIndex: getKey(index),
    key: getKey(index),
    index,
    sortOnAscending,
    sortOnDescending,
    onHeaderCell: ({index, sortOnAscending, sortOnDescending}) => ({
      onClick: handleColumnClick(index, sortOnAscending, sortOnDescending),
    }),
  }));

  const getRcDataFromCoreData = (keyBase = 0, moreProps={}) => ({columns, onPress}, key) =>
    columns.reduce((data, cell, index, visible) => ({
      [getKey(index)]: getCellContents(cell),
      key: getKey(`${keyBase}-${key}`),
      onPress: onPress,
      visible: visible,
      ...data,
      ...moreProps,
    }), {});

  let rcData;
  if(Array.isArray(table.rows)) {
    rcData = table.rows.map(getRcDataFromCoreData());
  } else {
    rcData = [];
    Object.entries(table.rows).map(([entityId, value], index) => {
      if(value.length > 1) {
      const expandableRow = {...value[0], onPress: toggleDisplayedEntity(entityId)};
        rcData.push(
          getRcDataFromCoreData(index, {className: 'accordion'})(expandableRow),
        );
      }
      if(value.length === 1 || displayedEntities.has(entityId)) {
        rcData = [...rcData, ...value.map(getRcDataFromCoreData(index, {className: displayedEntities.has(entityId) ? 'child' : ''}))];
      }
    });
  }

  return (<>
    {
      (table.clearFilters && table.filters) && (
        <Wrapper>
          { table.filters?.map(getFilterTab) }
        </Wrapper>
      )
    }
    {
      (table?.filterGroups?.exclusive?.selections) && (
        <Wrapper>
          { table.filterGroups.exclusive.selections.map(getExclusiveFilterTab) }
        </Wrapper>
      )
    }
    {  (filterContextView) &&
      <FilterController
        themeContext={themeContext}
        toggleAutofilter={() => {table.toggleAutofilter()}}
        doFiltering={table?.doFiltering?.bind(table)}
        value={!table?.doFiltering}
      />
    }
    {
      rcData?.length ?
        <>
          {(table?.expandable && table?.totalResults > 0) && (
            <TotalResults>Results <span>({table?.totalResults})</span></TotalResults>
          )}
          <TableWrapper
            hasSideFilters={hasSideFilters}
            rowDivisions={table.rowDivisions}
          >
            <StyledTable
              columns={rcColumns}
              data={rcData}
              onRow={({onPress, className}, index) => ({
                onClick: () => { onPress(); setSelectedRow(index); className == 'accordion' && toggleAccordion(index);},
                className: selectedRow === index ? `${className} selected${openAccordions.includes(index) ? ` open` : ``}` : `${className}${openAccordions.includes(index) ? ` open` : ``}`,
              })}
              height={height}
              hasFilters={table?.filterGroups?.exclusive?.selections}
              activeTheme={themeContext}
            />
          </TableWrapper>
        </>
        :
        <EmptyResults
          hasSideFilters={hasSideFilters}
          rowDivisions={table.rowDivisions}
          activeTheme={themeContext}
        >No Results</EmptyResults>
    }
    <MultiselectFilters className="allFilters">
      <>
        {
          hasSideFilters && table.filterGroups.multiSelect.map(({label, selections}) =>
            <FilterGroupDropdown
              handleGroupFilterClick={handleGroupFilterClick}
              themeContext={themeContext}
              label={label}
              selections={selections}
            />
          ) 
        }
      </>
      <>
        {filterContextView}
      </>
    </MultiselectFilters>
  </>);
};

export default Table;

const FilterController = ({themeContext, toggleAutofilter, doFiltering, value}) => <div
    style={{
      display: 'inline-block',
      paddingLeft: '1%',
      width: '13%',
      height: '40px'
    }}
  >
  <LogiclessCheckbox
    handleClick={toggleAutofilter}
    value={value}
    label="Autofilter"
    style={{
      display: 'inline-block',
    }}
  />
  {!value && <div
    style={{
      display: 'inline-block',
      position: 'relative',
      bottom: '35px',
      left: '80%',
    }}
    >{doFiltering && <Button
    type="primary"
    activeTheme={themeContext}
    label="Filter"
    onPress={doFiltering}
    />}</div>
  }
</div>

const TableWrapper = styled.div`
overflow-y: auto;
width: ${({hasSideFilters, rowDivisions}) => hasSideFilters ? '85' : 100/rowDivisions}%;
`;

const TotalResults = styled.span`
font-size: .75rem;
span {
    text-decoration: none;
}
`;

export const StyledTable = styled(RC_Table)`
width: 100%;
display: block;
font-family: ${theme.baseFont};
align-self: flex-start;
.rc-table-container {
  display: block;
  width: 100%;
}
max-height: ${({height}) => `${height-270}px`};
overflow-y: auto;

border-radius: ${({hasFilters}) => hasFilters ? '0 0 10px 10px' : '10px'};

table {
  background-color: #ffffff;
  border-collapse: separate;
  border-spacing: 0;
  position: relative;
  width: 100%;
};

tr.accordion td:nth-of-type(1) {
  border-left: 2px solid ${theme.blue};
}

tr.accordion:not(.open) td:nth-of-type(1)::before {
    font-family: FontAwesome;
    content: "\f061";
    position: absolute;
    left: 0;
    color: ${theme.blue};
}

tr.accordion.open td:nth-of-type(1)::before {
    font-family: FontAwesome;
    content: "\f149";
    position: absolute;
    left: 0;
    color: ${theme.blue};
    font-size: 16px;
}

tr.child td:nth-of-type(1) {
  border-left: 1px dotted ${theme.primary};
}

tr.child td.rc-table-cell {
    text-align: right;
}

.rc-table-cell {
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: 300;
}

thead {
    border-bottom: 1px solid black;

    .rc-table-cell {
        background-color: ${({ activeTheme }) => activeTheme.tableTheadBackground};
        border-right: 1px solid ${({ activeTheme }) => activeTheme.tableBorder};
        height: 30px;
        cursor: pointer;
        overflow: hidden;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
        padding: 2px;
        font-size: .65rem;
        color: ${({ activeTheme }) => activeTheme.tableHeaderText};
        font-weight: ${({ activeTheme }) => activeTheme.tableHeaderStyle};
    }
    .rc-table-cell:last-of-type {
        border-right: none;
    }
}

table th {
    border-bottom: 2px solid blue;
}

table tbody tr:hover td,
table tbody tr:hover .rc-table-cell {
    color: ${theme.sideBarBackground};
}

thead tr th {
  color: #a2a2a2;
  background-color: #ffffff;
  border-collapse: collapse;
  border-radius: 0;
  position: sticky;
  top: 0;
}

thead th { 
    z-index: 1;
    border-bottom: 1px solid ${({ activeTheme }) => activeTheme.tableBorder};
}

tbody td { 
    z-index: 2;
    border-bottom: 1px solid ${({ activeTheme }) => activeTheme.tableBorder};
}

tbody .rc-table-row:not(.accordion) {
  background-color: ${({ activeTheme }) => activeTheme.tableBackground};
  height: 35px;
}

tbody .rc-table-row.selected:not(.accordion),
tbody tr:hover .rc-table-row.selected:not(.accordion) {
  background-color: #e0e5ef;
}

tbody .rc-table-row {
  overflow: hidden;
  align-items: center;
  height: ${rowHeight}px;
  justify-content: space-between;
  > div:first-of-type {
  margin-left: 10px;
  }
  > div:last-of-type {
  margin-right: 10px;
  }
  position: relative;
}

.rc-table-tbody .rc-table-cell {
  max-height: 50px;
  overflow: hidden;
  padding: 0 10px;
  color: ${({ activeTheme }) => activeTheme.tableText};
  text-align: center;
}
`;

const EmptyResults = styled.span`
text-align: center;
font-size: 1.25rem;
margin-top: 20px;
width: ${({hasSideFilters, rowDivisions}) => hasSideFilters ? '85' : 100/rowDivisions}%;
color: ${({ activeTheme }) => activeTheme.inputText};
`;

const MultiselectFilters = styled.div`
flex-direction: column;
border-width: 1px;
width: 15%;
`;
const FilterGroup = styled.span`
.react-date-picker--open {
  z-index: 500;
}
border-radius: ${theme.borderRadius}px;
border: 1px solid ${({ activeTheme }) => activeTheme.tableFilterBorder};
color: ${theme.grey70};
margin: 0 0 15px 15px !important;
padding: 0 10px 10px;
margin: 5px;
`;

const FilterLabel = styled.p`
position: relative;
top: -3px;
cursor: pointer;
text-align: center;
text-transform: uppercase;
font-weight: 600;
margin: 0 0 10px;
color: ${({ activeTheme }) => activeTheme.inputText};
`;

export const FilterDropdownArrow = styled(Icon)`
position: relative;
top: 13px;
cursor: pointer;
`
