import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../theme';

const Image = ({ file }) => {
    console.log('f', file);
  return <PhotoContainer>
    <Photo src={file.src} />
  </PhotoContainer>;
};

Image.propTypes = {
  file: PropTypes.array,
};

const Photo = styled.img`
border: 1px solid ${theme.grey30};
border-radius: 2px;
padding: 5px;
height: 100%;
`;

const PhotoContainer = styled.div`
flex: 0 1 auto;
height: 100%;
`;

export default memo(Image);
