import React, { memo, useContext } from 'react';
import { useDispatch } from 'react-redux';
import navigation from '../../implementation/navigation';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { handleActions } from 'qcp-js-ui-core/actions';
import Icon from '../shared/Icon';
import { generateTempQuin } from 'qcp-js-ui-core/utils/uuid';
import { addDefaultModel } from 'qcp-js-ui-core/store/actions/rms';
import OutstandingRequests from './OutstandingRequests';

import theme from '../theme';

const goToRoute = async (rootView, navigationAction, dispatch) => {
  const fallbackContextAction = rootView?.fallbackContextAction;
  const action = fallbackContextAction
    ? [fallbackContextAction ?? undefined, navigationAction]
    : [navigationAction];
  const tempQuin = generateTempQuin();
  await dispatch(addDefaultModel(tempQuin, rootView.contextType));
  await dispatch( handleActions({json: action, quin: tempQuin, navigation}, true) );
};

const TopNavigation = ({ logo, items }) => {

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  if(location.pathname === '/' && items?.[0]) {
    const { route } = items[0];
    if(route !== '/') { location.pathname = route; }
  }

  return <Wrapper>
    <LogoLink href={window.qcpConfig?.oneappUrl} target="_blank" hasLogo={logo} activeTheme={themeContext}>
      {logo && <Image activeTheme={themeContext} src={logo} />}
      {!logo && (
        <Logo>
          <Icon color={themeContext.logoIconColor} name="cloud" font="Feather" size={20} />
          <LogoText>Quicket</LogoText>
        </Logo>
      )}
    </LogoLink>
    <Links key={`l-1`} activeTheme={themeContext}>

      {items.map(
        ({
          active,
          name,
          route,
          onClick: handleClick = () => {},
          external,
          action,
          rootView,
          subItems
        }, index) => {
          return (
            external ?
              <StyledFakeLinkButton key={index} onClick={handleClick}>
                <Button key={`b-${index}`}>
                  {name && name}
                </Button>
              </StyledFakeLinkButton>
              :
              <div key={`f-${index}`}>
              {subItems ?
                <DropdownContainer key={index}>
                  <StyledLink className={subItems && 'dropdown'} key={`s-${index}`} active={active ? 1 : 0} to={route} onClick={() => goToRoute(rootView, action, dispatch)}>
                    {name && <Bottom key={`b-${index}`}>{name}</Bottom>}
                  </StyledLink>
                  <DropdownItems key={`d-${index}`} className={'dropdown-items'} activeTheme={themeContext}>
                    <ul>
                      {subItems.map(({name, route, action, rootView}, index) => {
                        return (
                        <li key={`li-${index}`}>
                          <StyledSubLink to={route} onClick={() => goToRoute(rootView, action, dispatch)}>{name}</StyledSubLink>
                        </li>)
                      })}
                    </ul>
                  </DropdownItems>
                </DropdownContainer>
              :
              <StyledLink className={subItems && 'dropdown'} key={`s-${index}`} active={active ? 1 : 0} to={route} onClick={() => goToRoute(rootView, action, dispatch)}>
                {name && <Bottom key={`b-${index}`}>{name}</Bottom>}
              </StyledLink>
              }
              </div>
          );
        },
      )}
    </Links>
    <OutstandingRequests/>
  </Wrapper>;
};

TopNavigation.displayName = 'TopNav';

TopNavigation.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  bottomItems: PropTypes.arrayOf(PropTypes.object),
  hamburger: PropTypes.bool,
  hamburgerClick: PropTypes.func,
};

TopNavigation.defaultProps = {
  logo: '',
  items: [],
  hamburger: false
};

const areEqual = (prevProps, nextProps) => {
  const { items: items = [] } = prevProps;
  const { items: nextItems = [] } = nextProps;

  if (items.length !== nextItems.length) {
    return false;
  }

  for (let i = 0; i < items.length; i++) {
    if (
      items[i].active !== nextItems[i].active ||
items[i].icon !== nextItems[i].icon ||
items[i].name !== nextItems[i].name ||
items[i].type !== nextItems[i].type
    ) {
      return false;
    }
  }

  return true;
};

export default memo(TopNavigation, areEqual);
export { areEqual };

const DropdownContainer = styled.div`
position: relative;
&:hover .dropdown-items {
display: block;
}
`;

const DropdownItems = styled.div`
display: none;
position: absolute;
background-color: ${({ activeTheme }) => activeTheme.mainMenuDropdownBackground};
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
top: 30;
border-top: 1px solid ${theme.newOrange};
border-radius: 0 0 4px 4px;

ul {
list-style-type: none;
padding-left: 0;
margin: 5px 0 0 0;
li {
margin-bottom: 5px;
}
li:hover {
background-color: ${({ activeTheme }) => activeTheme.headerBackground};
a {
color: ${theme.greyBox};
}
}
li:last-of-type {
margin-bottom: 0;
border-radius: 0 0 4px 4px;
}
}
`;

const LogoLink = styled.a`
line-height: ${({ hasLogo }) => hasLogo ? '0' : '35px'};
text-decoration: none;
&:hover {
h1, i {
opacity: ${({ activeTheme }) => activeTheme.logoHoverOpacity};
}
}
`

const Image = styled.img`
height: 35px;
margin-right: 20px;
opacity: ${({ activeTheme }) => activeTheme.logoOpacity};
&:hover {
    opacity: ${({ activeTheme }) => activeTheme.logoHoverOpacity};
}
`;

Image.displayName = 'Image';

const Logo = styled.div`
justify-content: space-evenly;
width: 110px;
align-items: center;
`;

Logo.displayName = 'Logo';

const LogoText = styled.h1`
font-size: 16px;
font-weight: 500;
letter-spacing: 0.5px;
margin: 0;
color: white;
`;

LogoText.displayName = 'LogoText';

const Bottom = styled.div`
width: 100%;
align-items: center;
font-size: .75rem;
font-weight: inherit;
letter-spacing: 1px;
justify-content: center;
text-transform: uppercase;
color: white;
`;

const StyledSubLink = styled(Link)`
padding-left: 20px;
font-size: .75rem;
letter-spacing: 1px;
text-transform: uppercase;
text-decoration: none;
color: ${theme.sideBarBackground};
width: 100%;
opacity: 80%;
&:hover {
color: ${theme.greyBox};
}
`;



const StyledLink = styled(Link).attrs(({ active }) => ({
  className: active && 'active',
}))`
cursor: pointer;
display: flex;
flex-direction: column;
color: black;
text-decoration: none;
font-weight: 300;
opacity: 50%;
justify-content: center;
border-radius: 4px 4px 0 0;
padding: 10px;
margin: 0 0 0 10px;
text-transform: uppercase;

&.active {
border-bottom: 1px solid ${theme.bodyBackground};
font-weight: 600;
opacity: 100%;
margin-bottom: -1px;
}

&:hover {
background: ${theme.sideBarButton};
}
`;

const StyledFakeLinkButton = styled.div`
cursor: pointer;
height: auto;
width: 100%;
display: flex;
flex-direction: column;
color: white;
text-decoration: none;
align-self: center;
`;

const Button = styled.div`
align-items: center;
color: white;
justify-content: space-evenly;
background-color: ${theme.sideBarButton};
margin: 0 auto;
border-radius: 4px;
padding: 10px;

&:hover {
background: ${theme.sideBarActive};
}
`;

const Wrapper = styled.div`
flex-direction: row;
justify-content: flex-start;
flex: 1;
align-self: center;
`;

const Links = styled.div`
flex-direction: row;
flex: 1;
align-self: flex-end;
height: 30px;

a.active {
background: ${({ activeTheme }) => activeTheme.tabActiveBackground};
}
`;
