import React, { useEffect, useCallback, useRef, useState, useContext } from 'react';

import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useDropzone } from 'react-dropzone';

import Icon from '../shared/Icon';
import theme from '../theme';

const FileUpload = ({ height, onChange, files, setFiles }) => {
  const [totalSize, setTotalSize] = useState(0);
  const dropzone = useRef();

  const wrapperHeight = height || 200;
  const themeContext = useContext(ThemeContext);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(prevFiles => {
      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          timestamp: Date.now(),
        }),
      );

      return prevFiles.concat(newFiles);
    });
  }, []);

  const removeFile = (ev, fileToRemove) => {
    ev.stopPropagation();

    setFiles(prevFiles =>
      prevFiles.filter(prevFile => {
        if (
          prevFile.name === fileToRemove.name &&
prevFile.timestamp === fileToRemove.timestamp
        ) {
          return false;
        }

        return true;
      }),
    );
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const animation = useSpring({
    display: isDragActive ? 'flex' : 'none',
    opacity: isDragActive ? 1 : 0,
  });

  const formatFileSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  useEffect(() => {
    if (dropzone.current) {
      onChange(files);
    }
    if(files.length) {
      let totalFileSize = 0;
      files.map((f) => {
        totalFileSize = totalFileSize + f.size;
      });
      setTotalSize(totalFileSize);
    }
  }, [files]);

  const fileTypes = ['pdf', 'video', 'audio', 'csv', 'document', 'text'];
  const fileTypeIconMap = [{
      pdf: {
          name: 'file-pdf-o',
          font: 'FontAwesome'
      },
      video: {
        name: 'file-movie-o',
        font: 'FontAwesome'
      },
      audio: {
        name: 'file-audio-o',
        font: 'FontAwesome'
      },
      csv: {
        name: 'page-csv',
        font: 'Foundation'
      },
      document: {
        name: 'file-text-o',
        font: 'FontAwesome'
      },
      text: {
        name: 'file-text-o',
        font: 'FontAwesome'
      }
  }];
  const getFileIcon = (type) => {
    const foundType = fileTypes.filter((v, i) => type.includes(v));
    if(foundType.length > 0) {
      const foundIcon = fileTypeIconMap[0][foundType[0]];
      return ( <Icon name={foundIcon.name} font={foundIcon.font} size="36" /> )
    } else {
      return ( <Icon name="file-o" font="FontAwesome" size="36" /> )
    }
  }

  return (
    <Wrapper ref={dropzone} activeTheme={themeContext}>
      <DropContainer {...getRootProps()} height={wrapperHeight}>
        <Invisible {...getInputProps()} />
        {files && files.length > 0 && (
          <PreviewContainer>
            {files.map((file, index) => (
              <Preview key={`${file.name} ${index}`}>
                {file.type.includes('image') && (
                  <ImageContainer>
                    <Image src={file.preview} />
                    <span>Size: {formatFileSize(file.size)}</span>
                  </ImageContainer>
                )}
                {!file.type.includes('image') && (
                  <Placeholder>
                    { getFileIcon(file.type) }
                    <p>{file.name}</p>
                  </Placeholder>
                )}
                <X onClick={ev => removeFile(ev, file)}>&#10006;</X>
              </Preview>
            ))}
          </PreviewContainer>
        )}
        {!isDragActive && files && files.length === 0 && (
          <p>Drop Files or Click to Browse</p>
        )}
        {isDragActive && (
          <AnimatedOverlay style={animation}>
            <OverlayInner>
              <OverlayMessage>Drop Here</OverlayMessage>
            </OverlayInner>
          </AnimatedOverlay>
        )}
      </DropContainer>
      {totalSize > 0 &&
<span>Total Size: {formatFileSize(totalSize)}</span>
      }
    </Wrapper>
  );

};

FileUpload.propTypes = {
  height: PropTypes.number,
  onChange: PropTypes.func,
  setFiles: PropTypes.func,
  files: PropTypes.arrayOf(PropTypes.object),
};

FileUpload.defaultProps = {
  height: 0,
  onChange: () => {},
  files: [],
  setFiles: () => {},
  timeout: 86400000, // 24 hours
};

export default FileUpload;

const DropContainer = styled.div`
flex-direction: column;
justify-content: space-around;
min-height: ${({ height }) => height}px;
outline: 0;
padding: 10px;
text-align: center;
width: 100%;
`;

const Image = styled.img`
border: 1px solid ${theme.grey30};
border-radius: 2px;
padding: 5px;
height: 100%;
`;

const ImageContainer = styled.div`
flex: 0 1 auto;
height: 100%;
flex-direction: column;
`;

const Invisible = styled.input`
height: 0;
width: 0;
`;

const Overlay = styled.div`
background-color: rgba(0, 0, 0, 0.75);
bottom: 0;
left: 0;
position: fixed;
right: 0;
top: 0;
`;

const AnimatedOverlay = animated(Overlay);

const OverlayInner = styled.div`
align-items: center;
border: 4px dashed white;
border-radius: 5px;
height: calc(100% - 20px);
justify-content: center;
margin: 20px;
width: calc(100% - 20px);
`;

const OverlayMessage = styled.span`
color: white;
font-size: 20px;
`;

const Placeholder = styled.div`
align-items: center;
flex-direction: column;
padding: 10px 20px;
`;

const PreviewContainer = styled.div`
flex-direction: row;
flex-wrap: wrap;
overflow: auto;
padding-top: 16px;
padding-right: 10px;
`;

const Preview = styled.div`
box-sizing: border-box;
display: inline-flex;
margin-bottom: 30px;
margin-right: 8px;
height: 100px;
`;

const X = styled.span`
background: ${theme.grey30};
border-radius: 10px;
cursor: pointer;
height: 20px;
line-height: 20px;
font-size: 12px;
margin-left: -10px;
margin-top: -10px;
text-align: center;
width: 20px;
`;

const Wrapper = styled.div`
cursor: pointer;
flex-direction: column;
width: 100%;

p, span {
color: ${({ activeTheme }) => activeTheme.modalTitle};
}
`;
