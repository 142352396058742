import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { reducers, rootReducer } from 'qcp-js-ui-core/store/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'localforage';
import modal from './store/reducers/modal';
import elastic from './store/reducers/elastic';
import notification from './store/reducers/notification';
import inFlight from './store/reducers/inFlight';
import workerRequests from './store/reducers/workerRequests';
import { setSignInListener } from './utils/cognito-session'
import { clearNotification } from './store/actions/notification';

const localReducers = combineReducers({
  ...reducers,
  modal,
  elastic,
  notification,
  inFlight,
  workerRequests,
});

const userChangeChannel = new BroadcastChannel("loginStateChannel");

let currentUser;
try {
  currentUser = localStorage.getItem('loggedInUser');
} catch (e) {
  currentUser = 'unknown-user-service-worker';
}

export const getCurrentUser = () => currentUser;

userChangeChannel.addEventListener('message', (event) => {
  const { username, status } = JSON.parse(event?.data || {status: 'UNKNOWN', username: null});
  if(status !== 'SUCCESS') { return }
  if(username === currentUser) { return }
  if(!username && location?.pathname === '/login') { return }
  location.replace && location.replace(username ? '/' : '/login');
});

const persistConfig = {
  key: `main-user-${currentUser}`,
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['rms', 'authentication'],
};

const pReducer = persistReducer(persistConfig, rootReducer(localReducers));

const store = createStore(
  pReducer,
  {}, // default state of the application
  applyMiddleware(thunk),
);

export const sessionChangeListener = async (username) => {
  localStorage.setItem('loggedInUser', username);
}


if(currentUser !== 'unknown-user-service-worker') {
  setSignInListener(sessionChangeListener);
}

let userChangeListener = () => {};
export const setUserChangeListener = (listener) => {
  userChangeListener = listener;
  userChangeListener(currentUser);
}

export default store;
export const persistor = persistStore(store);

store.dispatch(clearNotification());
