import { openDB } from 'idb';

const db = openDB('CoreAsync', 1, {
  upgrade(db) {
    db.createObjectStore('CoreAsync');
  },
});

export const AsyncStorage = {
  setItem: async (key, value) => (await db).put('CoreAsync', value, key),
  getItem: async (key) =>  (await db).get('CoreAsync', key),
};
