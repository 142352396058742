import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  contents: null,
  modalType: null,
};

const modalReducer = createReducer(initialState, {
  DISPLAY_UPLOAD_FILE: (state, action) => {
    state.contents = action.contents;
    state.modalType = action.modalType;
  },
  HIDE_UPLOAD_FILE: (state) => {
    state.contents = null;
    state.modalType = null;
  },
});

export default modalReducer;
