import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Icon from 'react-web-vector-icons';
import Tooltip from './Tooltip';

// loading ? 1 : 0 avoids an error in the console
const IconDisplay = ({ name, font, spin, size, tooltip, onPress = () => {}, ...rest }) => (
  <Wrapped tooltip={tooltip} onClick={onPress} name={name} font={font} spin={spin ? 1 : 0} size={size} {...rest} />
);

IconDisplay.defaultProps = {
  color: 'black',
  name: 'loader',
  font: 'Feather',
  size: 24,
  spin: false,
  display: '',
};

IconDisplay.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.number,
  spin: PropTypes.bool,
  display: PropTypes.string,
};

export default IconDisplay;

const Wrapper = styled.div`

${({ display }) =>
    display &&
css`
    display: ${display}
`}
${({ spin }) =>
    spin &&
css`
i {
    animation: spin-animation 3s infinite;
    animation-timing-function: linear;
    display: inline-block;


    @keyframes spin-animation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
`}
`;

const Wrapped = styled(props => {
    const [displayed, setDisplayed] = useState(false);
    const tooltipRef = useRef();
    const showToolTip = () => {
        setDisplayed(true);
      };  
    
      const hideToolTip = () => {
        setDisplayed(false);
      }

  return <Wrapper onMouseEnter={() => showToolTip()} onMouseLeave={() => hideToolTip()} {...props} onClick={() => props.onClick()} spin={props.spin}>
      <Icon className="qcp-icon" {...props} />
      {(props.tooltip && displayed) &&
        <Tooltip tooltipRef={tooltipRef} shown={displayed} content={props.tooltip} placement="icon" delay={400} />
     }
      </Wrapper>;
})`
`;
