import React, { useEffect, useRef, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import theme from '../theme';

const FourZeroFour = ({url}) => {
  const themeContext = useContext(ThemeContext);
  let validURLs = JSON.parse(localStorage.getItem('qsURLs'));
  const prevUrlRef = useRef();
  useEffect(() => {
    prevUrlRef.current = url;
  }, [url]);

  const prevUrl = prevUrlRef.current;
  const shownUrl = prevUrl == '' || prevUrl === undefined ? url : prevUrl;

  return (
    <Wrapper>
      {validURLs && validURLs.includes(url) ?
        <div />
        :
        <>
          <H2 activeTheme={themeContext}>
            <span>4</span>
            <span>0</span>
            <span>4</span>
          </H2>
          <Error activeTheme={themeContext}>the URL <strong>{shownUrl}</strong> could not be found</Error>
        </>
      }
    </Wrapper>
  );

};

export default FourZeroFour;

const Wrapper = styled.div`
margin: 0 auto;
justify-content: center;
flex: 1;
flex-direction: column;
margin-bottom: 20%;
`;

const H2 = styled.h2`
font-size: 5rem;
text-align: center;
margin-bottom: 0;
border-bottom: 2px solid ${theme.orange};

span:nth-of-type(even){
color: ${({ activeTheme }) => activeTheme.fourEven};
}

span:nth-of-type(odd){
color: ${({ activeTheme }) => activeTheme.fourOdd};
}
`;

const Error = styled.p`
font-size: 1rem;
color: ${({ activeTheme }) => activeTheme.fourError};
`;
