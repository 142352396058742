import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../theme';
import { usePopper } from 'react-popper';

const Tooltip = (props) => {
  const { content, shown, tooltipRef, placement } = props;

  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const { styles, attributes } = usePopper(tooltipRef?.current, popperElement, {
    modifiers: [
        {
          name: "arrow",
          options: {
            element: arrowElement
          }
        },
        {
          name: "offset",
          options: {
            offset: [20, 0]
          }
        }
      ]
  });

  const iconStyle = {popper: { left: '20px', top: '-35px', position: 'absolute'}};
  return (
    <Fragment>
    { shown &&
      <PopperContainer ref={setPopperElement} style={placement == 'icon' ? iconStyle.popper : styles.popper} {...attributes.popper}>
        {content}
        <PopperArrow ref={setArrowElement} style={styles.arrow} id="tooltipArrow" />
      </PopperContainer>
    }
    </Fragment>
  );
};

Tooltip.propTypes = {
  direction: PropTypes.string,
  delay: PropTypes.number,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

Tooltip.defaultProps = {
  direction: 'left',
  delay: 400,
  content: '',
};

export default Tooltip;

const PopperContainer = styled.div`
box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
border-radius: 5px;
background-color: ${theme.sideBarBackground};
padding: 10px;
text-align: left;
max-width: 200px;
z-index: 999;
color: ${theme.white};
border: 1px solid ${theme.white};

&[data-popper-placement^='top'] > #tooltipArrow {
bottom: -20px;
:after {
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
border-radius: 10px 0px 0px 0px;
top: -20px;
left: -85px;
}
}
`;

const PopperArrow = styled.div`
position: absolute;
width: 10px;
height: 10px;
&:after {
content: " ";
background-color: ${theme.white};
box-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
position: absolute;
top: -10px;
left: -85px;
transform: rotate(90deg);
width: 10px;
height: 10px;
border-radius: 0px 10px 0px 0px;
}
`
