import 'regenerator-runtime/runtime';
import React from 'react';
import { render } from 'react-dom';
import App from './App';
import store, { setUserChangeListener } from './store';
import logo from '../static/quicket-new@4.png';
import theme from './components/theme';
import styled from 'styled-components';
import './initializeDependencies';
import { register } from './serviceWorkerControl';
import { datadogRum } from '@datadog/browser-rum';
require('react-web-vector-icons/fonts');
import { storeModels } from 'qcp-js-ui-core/models';
import { rewriteTables } from 'qcp-js-ui-core/component-logic/table';
import { setNotification } from './store/actions/notification';
import { setOutstandingRequests } from './store/actions/workerRequests';
import { tokenCheck } from './App';
import configs from 'qcp-js-ui-core/configs';
import api from 'qcp-js-ui-core/configs/api';
import { SORT_VALUE } from 'qcp-js-ui-core/utils/feature-negotiation/features';
import cognito from 'qcp-js-ui-core/utils/cognito';
import { getCurrentUser } from './store';
import { getCookie } from './implementation/setJwtToken';

register({});

const handleWorkerMessage = async (event) => {
  const { data: { messageType, url, statusCode }} = event;
  if(messageType === 'fetchError') {
    store.dispatch(setNotification(`Error: url ${url} returned with status code ${statusCode}`));
  } else if(messageType === 'storeAction' && event?.data?.action) {
    await store.dispatch(event.data.action);
  } else if(messageType === 'putInStore') {
    const storable = JSON.parse(event.data.storable);
    await storeModels(Array.isArray(storable) ? storable : [storable], store.dispatch);
    await rewriteTables();
  } else {
    store.dispatch(setNotification(`Error: unsupported service worker message type: ${messageType}`));
  };
}

const startTime = Date.now()
const willAcceptLogRocketState = () => {
  if(Date.now() - startTime > 5100) { return true }
  return !window.qcpConfig?.logrocketIngestServer && !window.qcpConfig?.logrocketSdkServer;
}

const configureServiceWorker = () => {
  if(!navigator?.serviceWorker?.ready) {
    setTimeout(configureServiceWorker, 250);
    return;
  }
  navigator.serviceWorker.ready.then((registration) => {
    setUserChangeListener((user) => {
      registration.active.postMessage({
        messageType: 'storeDispatch',
        message: {
          type: 'setUser',
          user,
        },
      });
    });
    registration.active.postMessage({
      messageType: 'storeDispatch',
      message: {
        type: 'setConfiguration',
        configuration: {
          ...window.qcpConfig,
          appName: window.qcpConfig?.appName || 'rms',
          apiUrl: window.qcpConfig?.apiUrl || 'http://rms-api-local.quicket.local:8081',
        },
      },
    });
  });
}

const rmsStartup = async () => {
  let hasDDLoaded = false;
  window.qcpConfig = await (await fetch('/deploy-config.json')).json();
  const { apiUrl, appName, authProvider, loginUser } = window.qcpConfig;
  const validAuthProviders = ['oneapp', 'local', 'cognito'];
  if(!validAuthProviders.includes(authProvider)) {
    return render(<p>"authProvider" in the /deploy-config.json must include one of: {validAuthProviders.toString()}</p>, document.getElementById('root'));
  }
  if(authProvider != 'cognito') {
    const currentUser = getCurrentUser();
    let username = loginUser;
    
    if(authProvider == 'oneapp') {
        try {
            const request = new Request(
            `${apiUrl}/${appName}/v1/session`,
            {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${getCookie('_token')}`,
                }
            }
            );
            const response = await (await fetch(request)).json();
                username = response?.session?.user?.username;
        } catch(e) {
            if(typeof e?.toString == 'function') {
                return render(<p>Error with OneApp Request: {e.toString()}</p>, document.getElementById('root'));
            } else {
                return render(<p>Error with OneApp Request</p>, document.getElementById('root'));
            }
        }
    }
        if(!username) {
            let redirectURL = `${window.qcpConfig?.oneappUrl}/${window.qcpConfig?.clientName}/home/index`;
            setTimeout(() => window.location=redirectURL, 3000);
            return render(<p>You are not logged in...redirecting back to OneApp, or <a href={redirectURL}>click here</a></p>, document.getElementById('root'));
        }
        if(currentUser !== username) {
            localStorage.setItem('loggedInUser', username);
            new BroadcastChannel("userChangeChannel").postMessage(
                JSON.stringify({username})
            );
        }
    
    
  }
  configureServiceWorker();
  configs.setClient(window.qcpConfig.appName);
  const webRMSClientConfig = {
    ...api,
    clientName: window.qcpConfig.clientName,
    rmsConfig: {
      appName: window.qcpConfig.appName,
      features: [SORT_VALUE],
      appDomain: window.qcpConfig.apiUrl,
      apiVersion: 1,
      useInternalSchema: false,
    },
    cognitoEnabled: false,
    clientS3Bucket: window.qcpConfig.s3Bucket,
    emailDomain: window.qcpConfig.emailDomain,
    origin: '',
    useLocalDev: false,
  };
  if (window.qcpConfig?.cognitoClientId && window.qcpConfig?.cognitoPoolId) {
    webRMSClientConfig.cognitoEnabled = true;
    webRMSClientConfig.cognito = {
      ...cognito,
      Auth: {
        ...cognito.Auth,
        region: window.qcpConfig.cognitoPoolId.split('_').at(0),
        userPoolId: window.qcpConfig.cognitoPoolId,
        userPoolWebClientId: window.qcpConfig.cognitoClientId,
      },
    };
  }

  configs.updateClientConfig({
    devConfig: { ...configs, ...webRMSClientConfig },
    prodConfig: { ...configs, ...webRMSClientConfig },
  });

  if(window.qcpConfig?.logrocketIngestServer && window.qcpConfig?.logrocketSdkServer) {
      window.LogRocket && window.LogRocket?.init(window.qcpConfig.logrocketApp, {
        ingestServer: window.qcpConfig.logrocketIngestServer,
        sdkServer: window.qcpConfig.logrocketSdkServer,
      });
      window.LogRocket?.getSessionURL(sessionURL => {
        window.currentURL = sessionURL || '';
      });
  }

  if(window.qcpConfig?.datadogApplicationId && window.qcpConfig?.datadogClientToken && !hasDDLoaded) {
    datadogRum.init({
      applicationId: window.qcpConfig.datadogApplicationId,
      clientToken: window.qcpConfig.datadogClientToken,
      site: window.qcpConfig.datadogSite,
      service: window.qcpConfig.datadogService,
      env: window.qcpConfig.datadogEnv,
      version: window.qcpConfig.datadogVersion,
      sampleRate: window.qcpConfig.datadogSampleRate,
      trackInteractions: window.qcpConfig.datadogTrackInteractions,
      allowedTracingOrigins: ['https://rms-dev.quicketsolutions.info', /https:\/\/.*\.quicketsolutions\.info/],
      silentMultipleInit: true
    });
    hasDDLoaded = true;
  }

  if(navigator?.serviceWorker?.addEventListener) {
    navigator.serviceWorker.addEventListener('message', handleWorkerMessage);
  } else {
    console.error('Starting without service worker. Performance will be hindered.');
  }
  if((window.currentURL || willAcceptLogRocketState())) {
    render(<App store={store} />, document.getElementById('root'));
  } else {
    setTimeout(rmsStartup, 500);
    render(<Loading />, document.getElementById('root'));
  }
};

const Loading = () => (
  <Wrapper>
    <Container>
      <Image src={logo} />
      <p>Loading.......</p>
    </Container>
  </Wrapper>
);

const Wrapper = styled.div`
background-color: ${theme.bodyBackground};
margin: -8px;
height: 100%;
`;

const Container = styled.div`
background-color: #e9eff0;
border-radius: 12px;
border: 4px solid ${theme.sideBarActive};
bottom: 0;
flex-direction: column;
justify-content: space-between;
left: 25%;
top: 25%;
position: absolute;
width: 50%;
height: 250px;
text-align: center;
z-index: 3;

p {
border-right: solid 5px rgba(255,255,255,.75);
white-space: nowrap;
overflow: hidden;
font-family: 'Open Sans';
font-size: 20px;
text-transform: uppercase;
color: rgb(24 85 167);
margin: 0 auto;
}

p {
animation: animated-text 4s linear 1s infinite alternate both, animated-cursor 600ms linear infinite;
}

@keyframes animated-text {
from{width: 0;}
to{width: 130px;}
}

@keyframes animated-cursor {
from{border-right-color: rgba(255,255,255,.75);}
to{border-right-color: transparent;}
}
`;

const Image = styled.img`
height: 150px;
margin: 20px auto 20px auto;
`;

rmsStartup();
