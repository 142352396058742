import store from '../store';
import { updateRoute } from 'qcp-js-ui-core/store/actions/navigator';

const getViews = () => store.getState().rms.schemas.views;

const getRoute = (name, quin) => {
  const path = getViews()[name].path.trim();
  const linkIndex = path[0] === '/' ? 1 : 0;
  const key = path.split('/')[linkIndex];
  return {
    link: quin ? `${key}/${quin}` : `${key}`,
    key,
    quin,
  };
};

const dispatch = async (navigatorCommand) => { };

const navigate = async (screen, command) => {
  const { params: {params: {name, id}}} = command;
  const currentRoutes = store.getState()?.navigator?.currentRoute || [{}];
  const lastRoute = currentRoutes[currentRoutes.length - 1];
  const route = getRoute(name, id);
  if(route.key === lastRoute.key && route.quin === lastRoute.quin) {
    return;
  }
  store.dispatch(updateRoute([route]));
};

export default { dispatch, navigate, getRoute };
