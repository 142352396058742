import React, { useEffect, useState, useContext } from 'react';
import styled, { css, ThemeContext } from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getModel } from 'qcp-js-ui-core/models';
import { handleActions } from 'qcp-js-ui-core/actions';
import { getJsonProperty, handleBinding } from 'qcp-js-ui-core/component-logic/binding';
import { openDB } from 'idb';
import theme from '../theme';

import ViewComponent from '../ViewComponent';

import store from '../../store';

const getRoute = (model, dispatch) => ({ label, action }) => {
  const match = action.link.href.match(/\{([^}]+)\}/);
  if(!match) {
    return { label, path: `${action.link.href}/${model.quin}` };
  }

  const replaceable = match[1];
  if(action.link.templatePointers) {
    const pointer = action.link.templatePointers[replaceable];
    const replacement = getJsonProperty(pointer, model.quin, dispatch);
    const path = action.link.href.replace(`{${replaceable}}`, replacement);
    return { path, label };
  }

  const simplePath = action.link.href.replace(`{${replaceable}}`, model[replaceable]);
  return { label, path: simplePath };
};

const getRouteObject = (link) => {
  const offset = link[0] === '/' ? 1 : 0;
  const parts = link.split('/');
  return {
    link: `/${link}`,
    key: parts[offset + 0],
    quin: parts[offset + 1],
  };
};

const loadModel = async (key, quin) => {
  const viewByPath = store.getState().rms.schemas.views.byPath;
  const contextType = viewByPath[key].contextType;
  const readActionName = store.getState().rms.schemas.modelSchema[contextType]
    ?.actions?.read;
  await store.dispatch(handleActions({ json: readActionName, quin: quin }));
};

const TabPane = ({ id, data, direction }) => {
  const dispatch = useDispatch();
  const model = useSelector(state => id && getModel(id, state.rms));
  const routes = data.map(getRoute(model, dispatch));
  const [onPane, setOnPane] = useState({});
  const [activeTab, setActiveTab] = useState('');
  const [prevTab, setPrevTab] = useState('');
  const currentRoutes = useSelector(state => state.navigator.currentRoute);
  const baseRoute = currentRoutes[0];
  const baseModel = useSelector(state => state.rms[baseRoute.quin]);
  let currentPath = window.location.pathname;
  const themeContext = useContext(ThemeContext);

  const db = openDB('TabPane', 1, {
    upgrade(db) {
      db.createObjectStore('TabPane');
    },
  });

  const getPrevious = async () => {
    return (await db).get('TabPane', 1).then(res => res);
  }

  const checkTab = async (routeObject) => {
    (await db).get('TabPane', routeObject.key)
      .then(res => {
        if(res) {
          setActiveTab(res);
        } else {
          getPrevious().then(res => {
            const storeNewTab = async () => { 
              setActiveTab(res); 
              (await db).put('TabPane', res, routeObject.key) 
            };
            storeNewTab();
          });
        }
      })
  }

  useEffect(() => {
    
    if(currentRoutes.length == 2) {
      //hits here during back button clicks
      const routeObject = getRouteObject(currentRoutes[1].link);
      let isTab = routes.filter(route => route.path == routeObject?.link?.replace(/^\//, ''));
      if(isTab.length > 0) {
        //get the index of the route based on its path
        let loadedRoute = routes.filter((item, index) => {
          if(item.path == isTab[0].path) {
            item.index = index;
            return item;
          }
        });
        setActiveTab(loadedRoute[0].index);
        (async () => (await db).put('TabPane', loadedRoute[0].index, loadedRoute[0].label)());
      } else {
        checkTab(routeObject);
      }
      setOnPane(routeObject);
      (async() => await loadModel(routeObject.key, routeObject.quin)());
    } else {
      let defaultUrl = '';
      //figure out if they're coming in on /reportView
      let pathArray = currentPath.split('/');
      if(pathArray.length < 5) {
        //push to first route index by default
        const routeObject = getRouteObject(routes[0]?.path);
        setActiveTab(0);
        setOnPane(routeObject);
        (async() => await loadModel(routeObject.key, routeObject.quin)());
        defaultUrl = defaultUrl.concat('/' + baseRoute.link + '/', routes[0].path);
        window.history.pushState({url: defaultUrl}, '', defaultUrl);
      } else {
        if(currentRoutes.length == 1) {
            // hits here when Add Supplement, Add Victim, Add Subject, etc...
            let path = pathArray[3] + '/' + pathArray[4];
            let loadedRoute = routes.filter((item, index) => {
              if(item.path == path) {
                item.index = index;
                return item;
              }
            });
            // Add Supplement does not return a route, so go back to Summary tab
            if(loadedRoute.length) { 
                (async() => await loadModel(pathArray[3], pathArray[4])());
            } else {
                onClickTab(0, true);
            }
        } else {
            const newRoute = Object.values(currentRoutes)[Object.values(currentRoutes).length - 1];
            setOnPane(newRoute);
            checkTab(newRoute);
            (async() => await loadModel(newRoute.key, newRoute.quin)());
            defaultUrl = defaultUrl.concat('/' + baseRoute.link + '/' + newRoute.link + '/', '');
            window.history.pushState({url: defaultUrl}, '', defaultUrl);
        }
      }
    }
  }, []);
  
  const onClickTab = async (tab, fullLink = false) => {
    setActiveTab(tab);
    (await db).put('TabPane', tab, routes[tab].label);
    (await db).put('TabPane', tab, 1);
    const routeObject = getRouteObject(routes[tab].path);
    setOnPane(routeObject);
    let pushedUrl = '';
    if(fullLink) {
        pushedUrl = pushedUrl.concat('/' + baseRoute.key + '/' + baseRoute.quin + '/', routes[tab].path);
    } else {
        pushedUrl = pushedUrl.concat('/' + baseRoute.link + '/', routes[tab].path);
    }
    window.history.pushState({url: pushedUrl}, '', pushedUrl);
    await loadModel(routeObject.key, routeObject.quin);
  };

  const Tab = ({label, totalOf, position, onClick}) => {
    let isActive = false;
    if (activeTab === position) {
      isActive = true;
    }
    const genSup = (label, totalOf) => <span>{label} <sup>{totalOf?.constValue}</sup></span>
    let displayLabel = label;
    if(totalOf) {
        displayLabel = genSup(label, totalOf);
    }
    return (
      <ListItem direction={direction} isActive={isActive} onClick={onClick} activeTheme={themeContext}>
        {displayLabel}
      </ListItem>
    );
  };

  return (
    <Wrapper direction={direction}>
      <Tabs direction={direction}>
        <TabList>
          {routes.map(({path, label}, index) => {
            return (
              <Tab
                activeTheme={themeContext}
                activeTab={activeTab}
                key={path}
                position={index}
                label={label}
                totalOf={data[index]?.totalOf}
                onClick={() => onClickTab(index)}
              />
            );
          })}
        </TabList>
      </Tabs>
      <ViewComponent route={onPane} />
    </Wrapper>
  );
};

TabPane.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array,
};

export default React.memo(TabPane);

const Wrapper = styled.div`
flex-direction: ${({ direction }) => direction == 'vertical' ? 'row' : 'column'};
width: 100%;
> div:first-of-type {
margin-bottom: 15px;
}
`;

const Tabs = styled.div`
    padding-left: 0;
    flex-direction: column;
    ${({ direction }) =>
        direction == 'vertical' &&
    css`    
        position: sticky;
        height: 300px;
        top: 35px;
        padding: 0 5px 20px 0;
        min-width: 100px;
    `}
`;

const ListItem = styled.li`
    display: ${({ direction }) => direction == 'vertical' ? 'block' : 'inline'};;
    list-style: none;
    padding: 0.25rem 0.25rem;
    cursor: pointer;
    ${({ direction }) => 
        direction == 'vertical' ?
    css`
    border-right: 1px solid #ccc;
    `
    :
    css`
    border-bottom: 1px solid #ccc;
    &:nth-of-type(1) {
        margin-left: 0;
    }
    margin-left: 15px;
    `};
    border-radius: 4px 0 0 4px;
    color: ${({ activeTheme }) => activeTheme.text};

${({isActive}) =>
    isActive &&
    css`
        color: ${theme.white};
        font-weight: 600;
        cursor: pointer;
        background-color: ${theme.gradientBlueLight};
        padding: 0.5rem 0.25rem;
        filter: ${({ activeTheme }) => activeTheme.filter};
    `
}
    &:hover {
        background: ${({isActive}) => isActive ? theme.gradientBlueLight : 'rgba(55, 165, 254, .2)'};
        color: ${({isActive, activeTheme}) => isActive ? theme.white : activeTheme.text};
    }
`;

const TabList = styled.ul`
    display: block;
    justify-content:space-between;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
`;

