import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import Modal from '../high-order/Modal';
import ViewComponent from '../ViewComponent';

const RouteView = ({ closeModal }) => {
  const {
    quin,
    link,
  } = useSelector(state => state.modal?.contents);

  const route = {
    link: '/' + link.href.replace('{quin}', quin),
    quin,
    key: link.href.split('/')[0],
  };
  
  useEffect(() => {
    document.body.classList.add('modal-open');
  }, [])

  const currentRoutes = useSelector(store => store.navigator?.currentRoute || [{}]);
  const viewState = useSelector(state => state.rms?.viewState?.[currentRoutes[1]?.link]);
  const displayedTitle = viewState?.windowTitle ? viewState.windowTitle : '';
  return (
    <Modal title={displayedTitle} visible={true} onClose={closeModal}>
      <ViewComponent route={route} />
    </Modal>
  );
};

RouteView.displayName = 'RouteView';

RouteView.propTypes = {
  closeModal: PropTypes.func,
};

export default RouteView;
